import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";

const BreadcrumbComponent = (props) => {
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item active>
          <Link
            to={props.fromHref}
            style={{ color: "black", textDecoration: "none" }}
          >
            {props.from}
          </Link>
        </Breadcrumb.Item>
        {/* <Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
          Library
        </Breadcrumb.Item> */}
        <Breadcrumb.Item active>
          {" "}
          <Link
            to={props.toHref}
            style={{ color: "black", textDecoration: "none" }}
          >
            {props.to}
          </Link>
        </Breadcrumb.Item>
        {props.thirdPage ? (
          <Breadcrumb.Item active>
            <Link
              to={props.thirdPageHref}
              style={{ color: "black", textDecoration: "none" }}
            >
              {props.thirdPage}
            </Link>
          </Breadcrumb.Item>
        ) : (
          ""
        )}
        {props.fourth ? (
          <Breadcrumb.Item active>
            <Link
              to={props.fourthHref}
              style={{ color: "black", textDecoration: "none" }}
            >
              {props.fourth}
            </Link>
          </Breadcrumb.Item>
        ) : (
          ""
        )}
      </Breadcrumb>
    </>
  );
};
export default BreadcrumbComponent;
