import React from "react";
import "../../scss/include/error500Page.scss";
import vectorImage from "../../assets/Others/vector right.svg";

const Error500Page = () => {
  return (
    <>
      <div className="container  p-0">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-12 d-flex  justify-content-center align-items-center  p-0">
            <div className="col-lg-12 col-md-12 col-12  h-100 d-flex flex-column justify-content-center align-items-center text-center">
              <span className="Text500heading">500</span>
              <span className="text500 text-capitalize font-weight-bold">
                OOPS! There was internal server error
              </span>
              <span className="text500">
                Don't worry our development team have automatically been
                notified of the issue and they are working on it. Please try
                again in few minutes.
              </span>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-12 d-flex justify-content-center align-items-center error500imageDiv">
            <img
              style={{ height: "70vh" }}
              // src={require('../../assets/Others/vector right.svg')}
              src={vectorImage}
              className="img-fluid"
              alt="builder_vector_image"
            />
          </div>
        </div>
      </div>{" "}
    </>
  );
};
export default Error500Page;
