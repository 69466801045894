import React, { useState } from "react";
import BreadcrumbComponent from "../CustomComponents/BreadcrumbComponent.js";
import "../../scss/include/contactUs.scss";
import DownloadOurAPpImgComponent from "../CustomComponents/DownloadOurAPpImgComponent";
import api from "../../services/api.js";
import { consoleError } from "../../utils/helper.js";
import FailedMessage from "../CustomComponents/FailedMessage";
import SuccessMessage from "../CustomComponents/SuccessMessage";
import CustomButtons from "../CustomComponents/CustomButtons";
import useTrans from "../hooks/useTrans.js";

const initialState = {
  question: "",
  name: "",
  email: "",
  phone: "",
  comment: "",
};

const ContactUs = () => {
  const trans = useTrans();
  // const [state, setState] = useState({
  //   name: "",
  //   email: "",
  //   mobileNumber: "",
  //   comment: "",
  // });

  // const formHandler = (event) => {
  //   const { name, value } = event.target;
  //   setState((prevValue) => {
  //     return {
  //       ...prevValue,
  //       [name]: value,
  //     };
  //   });
  // };
  // const submitForm = (event) => {
  //   event.preventDefault();
  //   alert(
  //     ` Name is ${state.name} and Email is ${state.email} and Mobile Number is ${state.mobileNumber} and Comment is ${state.comment}`
  //   );
  // };

  const [state, setState] = useState(initialState);
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [submitting, setSubmitting] = useState(false);
  // const handleChange = (event) => {
  //   setState({ value: event.target.value });
  // };

  const formHandler = (event) => {
    const { name, value } = event.target;
    setState((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };
  const submitForm = async (event) => {
    event.preventDefault();
    setErrMsg("");
    setSuccessMsg("");
    setSubmitting(true);
    try {
      const res = await api.post("/api/v1/contact", state);
      if (res && res.ok) {
        setSuccessMsg(res.message);
        setSubmitting(false);
        setState(initialState);
      }
    } catch (err) {
      consoleError(err);
      if (
        err &&
        err.errors &&
        Array.isArray(err.errors) &&
        err.errors.length > 0
      ) {
        setSubmitting(false);
        setErrMsg(err.errors[0].message);
      } else if (err && err.message) {
        setSubmitting(false);
        setErrMsg(err.message);
      } else {
        setErrMsg("Error submitting.");
        setSubmitting(false);
      }
    }
  };
  return (
    <>
      <BreadcrumbComponent
        from={trans.home}
        fromHref="/"
        to={trans.contactUs}
        toHref="/contact"
      />
      <div className="container-fluid ">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 ">
            <span className="contactUsHeading">{trans.getInTouch}</span>
          </div>
          <div className="col-lg-12 col-md-12 col-12 d-flex flex-column">
            <span className="mt-3 contactDescSpan">{trans.contactUsPara1}</span>
          </div>
        </div>

        <form onSubmit={submitForm}>
          <div className="row d-flex justify-content-center align-items-center mt-3">
            <div className="text-center">
              {successMsg ? <SuccessMessage msg={successMsg} /> : null}
              {errMsg ? <FailedMessage msg={errMsg} /> : null}
            </div>
            <div className="col-lg-12 col-md-12 col-12 d-flex justify-content-center align-items-center selectBoxDivContactUs">
              <div className="col-lg-2 col-md-2 col-2 d-flex justify-content-center align-items-center">
                <span className="mr-3 contactDescSpan">
                  {trans.selectQuestion}
                </span>
              </div>
              <div className="col-lg-6 col-md-6 col-9  d-flex justify-content-center align-items-center">
                <select
                  className="form-control contactDescSpan"
                  id="exampleFormControlSelect1"
                  onChange={formHandler}
                  name="question"
                  value={state.question}
                  required
                >
                  <option value="">{trans.select}</option>
                  <option value="I have a general suggestion or question">
                    {trans.contactUsQ1}{" "}
                  </option>
                  <option value="I have a question about online shopping">
                    {trans.contactUsQ2}
                  </option>
                  <option value="I have a question about bhavikaonline products">
                    {trans.contactUsQ3}
                  </option>
                  <option value="I have a complaint">
                    {trans.contactUsQ4}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-12 col-md-12 col-12 d-flex justify-content-center align-items-center selectBoxDivContactUs pt-4">
              <div className="col-lg-2 col-md-2 col-2 d-flex justify-content-center align-items-center contactDescSpan">
                <span>{trans.name}</span>
              </div>
              <div className="col-lg-6 col-md-6 col-9  d-flex justify-content-center align-items-center">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={state.name}
                  onChange={formHandler}
                  autoComplete="name"
                  required
                />
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-12 col-md-12 col-12 d-flex justify-content-center align-items-center selectBoxDivContactUs pt-4">
              <div className="col-lg-2 col-md-2 col-2 d-flex justify-content-center align-items-center contactDescSpan">
                <span>{trans.email}</span>
              </div>
              <div className="col-lg-6 col-md-6 col-9  d-flex justify-content-center align-items-center">
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  value={state.email}
                  onChange={formHandler}
                  autoComplete="email"
                  required
                />
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-12 col-md-12 col-12 d-flex justify-content-center align-items-center selectBoxDivContactUs pt-4">
              <div className="col-lg-2 col-md-2 col-2 d-flex justify-content-center align-items-center contactDescSpan">
                <span>{trans.phoneNumber}</span>
              </div>
              <div className="col-lg-6 col-md-6 col-9  d-flex justify-content-center align-items-center">
                <input
                  type="tel"
                  className="form-control"
                  name="phone"
                  value={state.phone}
                  onChange={formHandler}
                  autoComplete="phone"
                  required
                />
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-12 col-md-12 col-12 d-flex justify-content-center align-items-center selectBoxDivContactUs pt-4">
              <div className="col-lg-2 col-md-2 col-2 d-flex justify-content-center align-items-center contactDescSpan">
                <span>{trans.comment}</span>
              </div>
              <div className="col-lg-6 col-md-6 col-9  d-flex justify-content-center align-items-center">
                <textarea
                  type="text"
                  className="form-control"
                  name="comment"
                  value={state.comment}
                  onChange={formHandler}
                  rows={4}
                  required
                />
              </div>
            </div>
          </div>
          {/* <div className="text-center">
            {successMsg ? <SuccessMsg message={successMsg} /> : null}
            {errMsg ? <ErrorMsg message={errMsg} /> : null}
          </div> */}
          <div className="row d-flex justify-content-center align-items-center">
            {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
            <div className="col-lg-12 col-md-12 col-12 d-flex justify-content-center align-items-center selectBoxDivContactUs pt-4">
              {/* <button
                type="submit"
                className="contactUsSubmitButton contactDescSpan"
                disabled={submitting}
              >
                {submitting ? "Submitting..." : "Submit"}
              </button> */}
              <CustomButtons
                color="yellowgreen"
                width="150px"
                height="40px"
                text={submitting ? trans.submit + "..." : trans.submit}
                type="submit"
                submitting={submitting}
              />
            </div>
          </div>
        </form>
      </div>
      <DownloadOurAPpImgComponent />
    </>
  );
};

export default ContactUs;
