import React, { useState } from "react";
//import GoogleLogin from "react-google-login";
import { useGoogleLogin } from "@react-oauth/google";
import { API_BASE_URL, GOOGLE_APP_ID } from "../../config";
import qs from "qs";
import { consoleError, consoleLog } from "../../utils/helper";
import api from "../../services/api";
import localCart from "../../services/cart";
import { BhavikaContext } from "../Context/Bhavika";
import { useContext } from "react";
import { CartContext } from "../Context/Cart";
import reallocalCart from "../../services/realCart";
import { Button } from "react-bootstrap";

export default function GoogleLoginButton({ setErrorMessage }) {
  const [disabled, setDisabled] = useState(false);
  const { setIsAuthenticated, setLoginShow, setUser } =
    useContext(BhavikaContext);
  const { setCart, setRealCart } = useContext(CartContext);

  const successResponseGoogle = async (res) => {
    setErrorMessage("");
    consoleLog(res);
    const { code } = res;
    let query = qs.stringify({ code });
    if (code) {
      try {
        const res = await api.get(
          `${API_BASE_URL}/api/v1/auth/google?${query}`
        );
        if (res && res.ok) {
          consoleLog(res);
          localStorage.setItem("accessToken", res.accessToken);
          localStorage.setItem("userData", JSON.stringify(res.user));
          setUser(res.user);

          setIsAuthenticated(true);

          if (localCart.getCart().length > 0) {
            const res = await api.post("/api/v1/user/cart/localcart", {
              cart: localCart.getCart(),
            });
            if (res && res.ok) {
              localCart.emptyCart();
              setCart(res.data);
            }
            setLoginShow(false);
          }
          if (reallocalCart.getCart().length > 0) {
            const res = await api.post("/api/v1/user/realcart/localcart", {
              cart: reallocalCart.getCart(),
            });
            if (res && res.ok) {
              reallocalCart.emptyCart();
              setRealCart(res.data);
            }
            setLoginShow(false);
          }
        }
      } catch (err) {
        consoleError(err);
        setErrorMessage("Google login failed. Please try again.");
      }
    } else {
      consoleLog("No 'code' on google response.");
      setErrorMessage("Google login failed. Please try again.");
    }
  };
  const failureResponseGoogle = (res) => {
    const { error } = res;
    setErrorMessage("");
    consoleLog({ message: "Google login failed", ...res });
    if (error === "idpiframe_initialization_failed") {
      setDisabled(true);
      return;
    }
    setErrorMessage("Google login failed. Please try again.");
  };
  const login = useGoogleLogin({
    client_id: GOOGLE_APP_ID,
    onSuccess: (codeResponse) => successResponseGoogle(codeResponse),
    onError: failureResponseGoogle,
    flow: "auth-code",
  });

  return (
    // <GoogleLogin
    //   clientId={GOOGLE_APP_ID}
    //   buttonText="Login with Google"
    //   onSuccess={successResponseGoogle}
    //   onFailure={failureResponseGoogle}
    //   cookiePolicy={"single_host_origin"}
    //   responseType="code"
    //   disabled={disabled}
    // />
    <Button
      onClick={login}
      variant="light"
      style={{ border: "1px solid #ccc" }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        width="30px"
        height="30px"
      >
        <path
          fill="#FFC107"
          d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
        />
        <path
          fill="#FF3D00"
          d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
        />
        <path
          fill="#4CAF50"
          d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
        />
        <path
          fill="#1976D2"
          d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
        />
      </svg>
      <span className="ml-2">Login with Google</span>
    </Button>
  );
}
