import React from "react";
import { Link } from "react-router-dom";
import "../../scss/include/paymentMethod.scss";
import BreadcrumbComponent from "../CustomComponents/BreadcrumbComponent";
import useTrans from "../hooks/useTrans";

const PaymentMethod = () => {
  const trans = useTrans();
  return (
    <>
      <BreadcrumbComponent
        from={trans.home}
        fromHref="/"
        to={trans.paymentMethods}
        toHref="payment-methods"
      />

      <div className="container-fluid mb-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12  d-flex flex-column">
            <span className="paymentMethodHeadingSpan mb-2">
              How do I pay for <Link to="/">bhavika.online</Link> purchase?
            </span>
            <ol>
              <li className="paymentMethodDescSpan">By PayMe</li>
              <li className="paymentMethodDescSpan">Cash On Delivery</li>
            </ol>
          </div>
        </div>
      </div>

      <div className="container-fluid mb-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12  d-flex flex-column">
            <span className="paymentMethodHeadingSpan mb-2">
              Are there any hidden charges?
            </span>
            <span className="paymentMethodDescSpan">
              There are NO hidden charges when you shop at{" "}
              <Link to="/">bhavika.online</Link> and the listed price are final
              and all-inclusive.
            </span>
            <span className="paymentMethodDescSpan mt-2">
              If you have any queries, please contact us via email at{" "}
              <a
                href="mailto:info@bhavika.online"
                style={{ textDecoration: "underline" }}
              >
                info@bhavika.online
              </a>{" "}
              or by WhatsApp on{" "}
              <a href="https://wa.me/+85297777510">+852-9777-7510</a> or call us
              on <a href="tel:+85224154777">+852-2415-4777</a>.
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default PaymentMethod;
