import React from "react";
import BreadcrumbComponent from "../CustomComponents/BreadcrumbComponent";
// import ShopByHeading from "../CustomComponents/ShopByHeading";
import "../../scss/include/pointSystemInfo.scss";
import { Link } from "react-router-dom";
import useTrans from "../hooks/useTrans";

const PointSystemInfo = () => {
  const trans = useTrans();
  return (
    <>
      <BreadcrumbComponent
        from={trans.home}
        fromHref="/"
        to={trans.dollarRewardSystem}
        toHref="dollar-reward-system"
      />
      {/* <ShopByHeading title="How It Works"/> */}
      <div className="container-fluid">
        <div className="container-fluid mb-3">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <h2 className="rewardPointHeading">{trans.howItWorks}</h2>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-12 mb-2">
              <h3 className="rewardPointHeadingSpan">{trans.spendDollars}</h3>
            </div>
            <div className="col-sm-12 col-md-12 col-12">
              <div className="rewardPointDescriptionSpan">
                {/* <i className="fa fa-angle-double-right mr-2"></i> */}
                When you place an order for HK$300 at{" "}
                <Link to="/">bhavika.online</Link> a sum of HK$5 will be
                credited into your Dollar Reward Account. In the same order for
                each increment of HK$100 you will be rewarded HK$2 each.
              </div>
              <div className="rewardExample  mt-2">
                <h6>
                  <i>Example:</i>
                </h6>
                <i>
                  If you spend HK$400 you get HK$5 + HK$2, i.e., HK$7 as Reward
                  Dollars.
                  <br />
                  Similarly on spending HK$500 you will get HK$5 + HK$2 + HK$2,
                  i.e., HK$9 and so on.
                </i>
              </div>
              <div className="mt-2">
                <span className="noteSpan">
                  <strong>NOTE:</strong> The Dollar Rewards earned in a
                  particular transaction will accrue to your account only after
                  that transaction is complete. So you can use the Dollar
                  Rewards earned <em>NOW</em> only in your <em>NEXT</em>{" "}
                  transaction.
                </span>
              </div>
            </div>

            {/* How to see Reward Dollar Starts */}
            <div className="col-sm-12 col-md-12 col-12  mt-3 mb-2">
              <h3 className="rewardPointHeadingSpan">{trans.howToSee}</h3>
            </div>
            <div className="col-sm-12 col-md-12 col-12">
              <div className="rewardPointDescriptionSpan">
                {/* <i className="fa fa-angle-double-right mr-2"></i> */}
                <p>
                  You can visit your profile to see your accumulated reward
                  dollars.
                </p>
                <p>
                  Go to your <i>Profile</i> and then <i>Reward Dollar</i>.
                </p>
              </div>
              <div></div>
            </div>
            {/* How to see Reward Reward Dollar Ends */}

            {/* How To Redeem Rewards Starts */}
            <div className="col-sm-12 col-md-12 col-12  mt-3 mb-2">
              <h3 className="rewardPointHeadingSpan">{trans.howToRedeem}</h3>
            </div>
            <div className="col-sm-12 col-md-12 col-12">
              <div className="rewardPointDescriptionSpan">
                {/* <i className="fa fa-angle-double-right mr-2"></i> */}
                <p>
                  You can redeem your reward dollars at the time you checkout.
                </p>
                <p>
                  If you have accumulated Reward Dollars in your account you
                  will be prompted via a pop-up asking whether you wish to use
                  your Dollar Reward as part of your payment.
                </p>
                <p>
                  You can choose to say Yes and use your Dollar Reward right
                  away or let the Dollar Rewards accumulate in your account
                </p>
              </div>
            </div>
            {/* How To Redeem Reard Dollar Ends */}
          </div>
        </div>
      </div>
    </>
  );
};
export default PointSystemInfo;
