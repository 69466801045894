import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import api from "../../services/api";
import ProductListWithSearch from "../CustomComponents/ProductListWithSearch";
import FailedMessage from "../CustomComponents/FailedMessage";
import { consoleError } from "../../utils/helper";
import { Badge, Card, Col, ListGroup, Row } from "react-bootstrap";
import "../../scss/include/searchPage.scss";
import BrandList from "../CustomComponents/BrandList";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default function SearchPage() {
  const query = useQuery();
  const q = query.get("q");
  const [selectedFilter, setSelectedFilter] = useState("products");
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [errorMessage, setErrorMessage] = useState("Error here");
  useEffect(() => {
    async function search() {
      setErrorMessage("");
      try {
        const res = await api.get(`/api/v1/fullsearch?q=${q}`);
        if (res && res.ok) {
          const resProducts = res.data.products;
          const resBrands = res.data.brands;
          const products = Array.isArray(resProducts)
            ? resProducts.filter((product) => product.enabled)
            : [];
          setProducts(products);
          if (products.length === 0) {
            setSelectedFilter("brands");
          }
          const brands = resBrands;
          setBrands(brands);
        }
      } catch (error) {
        consoleError(error);
        if (error && error.message) setErrorMessage(error.message);
        else setErrorMessage("Search error.");
      }
    }
    if (q) {
      search();
    }
  }, [q]);
  return (
    <div className="searchPage">
      {errorMessage && <FailedMessage msg={errorMessage} />}
      <Row>
        <Col lg={2} md={3} sm={4} xs={12}>
          <Card className="filterBox">
            <Card.Header>
              <h1 className="filterTitle">Filters</h1>
            </Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item
                active={selectedFilter === "products"}
                onClick={() => setSelectedFilter("products")}
              >
                Products{" "}
                <span>
                  <Badge
                    pill
                    variant={
                      selectedFilter === "products" ? "light" : "primary"
                    }
                  >
                    {products.length}
                  </Badge>
                </span>
              </ListGroup.Item>
              <ListGroup.Item
                active={selectedFilter === "brands"}
                onClick={() => setSelectedFilter("brands")}
              >
                Brands{" "}
                <span>
                  <Badge
                    pill
                    variant={selectedFilter === "brands" ? "light" : "primary"}
                  >
                    {brands.length}
                  </Badge>
                </span>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
        <Col lg={10} md={9} sm={8} xs={12}>
          {selectedFilter === "products" && (
            <ProductListWithSearch productList={products} />
          )}
          {selectedFilter === "brands" && <BrandList brands={brands} />}
        </Col>
      </Row>
    </div>
  );
}
