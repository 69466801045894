import React, { useEffect, useState } from "react";
import Axios from "axios";
import { API_BASE_URL } from "../../config";
import SuccessMessage from "../CustomComponents/SuccessMessage";
import FailedMessage from "../CustomComponents/FailedMessage";

const EmailConfirmation = () => {
  // const [queryToken,setQueryToken]=useState('');
  const [successMsg, setSuccessMsg] = useState("");
  const [failedMsg, setFailedMsg] = useState("");

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let token = params.get("token");
    if (token) {
      // setQueryToken(token);
      Axios.get(`${API_BASE_URL}/api/v1/auth/confirmation/${token}`)
        .then((res) => {
          // consoleLog(res)
          setSuccessMsg(
            "Verify Successfully.Redirecting to homepage.Please Wait..."
          );
          setTimeout(function () {
            window.location.href = "/";
          }, 5000);
        })
        .catch((err) => {
          // consoleLog(err)
          setFailedMsg("Unable to verify your account");
        });
    } else {
      window.location.href = "/";
    }
  }, []);

  return (
    <>
      {successMsg ? (
        <SuccessMessage msg={successMsg} />
      ) : failedMsg ? (
        <FailedMessage msg={failedMsg} />
      ) : (
        <div className="container-fluid mt-3">
          <div className="row d-flex justify-content-center align-items-center ">
            <div className="col-lg-6 col-md-6 col-12 d-flex justify-content-center flex-column align-items-center">
              <span
                className="font-weight-bold mb-3"
                style={{ fontSize: "25px" }}
              >
                Verify your email address
              </span>
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className=" d-flex flex-column mt-3 mb-3">
                <span>
                  Thank you for registrating in Bhavika,
                  <br />
                  <br />
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default EmailConfirmation;
