import React, { useContext, useEffect } from "react";
import "../../scss/include/_bestBuyCard.scss";
import { BestBuyContext } from "../Context/BestBuy";
import api from "../../services/api";

import ProductSection from "./ProductSection";
import { consoleLog } from "../../utils/helper";

const BestBuyCard = () => {
  const { bestBuy, setBestBuy } = useContext(BestBuyContext);
  useEffect(() => {
    async function getBestBuy() {
      // TODO: change url later
      const res = await api.get("/api/v1/bestbuy/random");
      if (res && res.data) {
        consoleLog(res);
        setBestBuy(res.data);
      }
    }
    getBestBuy();
  }, [setBestBuy]);

  return (
    <>
      <div className="container-fluid best-buy">
        <div className="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-6">
          {bestBuy.map((product, index) => (
            <React.Fragment key={index}>
              <ProductSection product={product} type={"bestbuyproduct"} />
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};
export default BestBuyCard;
