import React from "react";
import "../../scss/include/ourService.scss";
import img1 from "../../assets/OurServices/img1.PNG";
import img2 from "../../assets/OurServices/img2.PNG";
// import img3 from "../../assets/OurServices/img3.PNG";
import img4 from "../../assets/OurServices/img4.PNG";

const OurService = () => {
  return (
    <>
      <div className="container-fluid mt-5  ">
        <div className="row d-flex justify-content-center align-items-center ">
          <div className="col-lg-5 col-md-5 col-12  BoxDiv1 d-flex  justify-content-center align-items-center m-2">
            <div className="d-flex flex-column justify-content-center align-items-center ">
              <span className="serviceHeading">FAST & RELIABLE</span>
              <span className="font-weight-bold mb-3 serviceBoldText">
                HOME DELIVERY
              </span>
              <a href="/shipping">
                <button className="serviceBtn" style={{ outline: "none" }}>
                  Schedule Now
                </button>
              </a>
            </div>
            <img src={img1} className="img-fluid" alt="scheduleNow" />
          </div>
          <div className="col-lg-5 col-md-5 col-12  BoxDiv2 d-flex  justify-content-center align-items-center m-2">
            <img src={img2} className="img-fluid" alt="img2" />
            <div className="d-flex flex-column justify-content-center align-items-center ">
              <span className="serviceHeading">CONTACT OUR</span>
              <span className="font-weight-bold mb-3 serviceBoldText">
                HELP CENTER
              </span>
              <a href="/contact">
                <button className="serviceBtn" style={{ outline: "none" }}>
                  Contact Now
                </button>
              </a>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-12  BoxDiv3 d-flex  justify-content-center align-items-center m-2">
            <div className="d-flex flex-column justify-content-center align-items-center ">
              <span className="serviceHeading">YOUR DATA IS</span>
              <span className="font-weight-bold mb-3 serviceBoldText">
                SSL SECURED
              </span>
              <button className="serviceBtn" style={{ outline: "none" }}>
                Learn More
              </button>
            </div>
            <img src={img4} className="img-fluid" alt="img3" />
          </div>
          <div className="col-lg-5 col-md-5 col-12  BoxDiv4 d-flex  justify-content-center align-items-center m-2">
            <img src={img4} className="img-fluid" alt="img4" />
            <div className="d-flex flex-column justify-content-center align-items-center ">
              <span className="serviceHeading">VIEW OUR</span>
              <span className="font-weight-bold mb-3 serviceBoldText">
                COMMUNITY
              </span>
              <a href="/blog">
                <button className="serviceBtn" style={{ outline: "none" }}>
                  Learn More
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OurService;
