import React, { useContext, useEffect, useState } from "react";
import CategoryPageProductHeading from "../CustomComponents/CategoryPageProductHeading";
import BreadcrumbComponent from "../CustomComponents/BreadcrumbComponent";
// import Dropdown from "react-bootstrap/Dropdown";
import ProductSection from "../CustomComponents/ProductSection";
import api from "../../services/api";

import { SubCategoryContext } from "../Context/SubCategory";
import PNAImage from "../../assets/Others/Product not found vector.svg";
import { consoleError } from "../../utils/helper";
import useTransPara from "../hooks/useTransPara";
import PageSpinner from "../CustomComponents/PageSpinner";

const SubCategoryPage = (props) => {
  const { subCategory, setSubCategory } = useContext(SubCategoryContext);

  const [pageLoading, setPageLoading] = useState(true);
  const [catLoading, setCatLoading] = useState(true);

  const transName = useTransPara("name");

  const [category, setCategory] = useState(null);

  const [searchProduct, setSearchProduct] = useState("");
  const {
    match: { params },
  } = props;
  const { category: slug1, subcategory: slug2 } = params;

  useEffect(() => {
    async function getSubCategoryDetails() {
      if (slug1 && slug2) {
        try {
          const res = await api.get(`/api/v1/category/slug/${slug1}/${slug2}`);
          if (res && res.data) {
            setSubCategory(res.data);
          }
        } catch (error) {
          consoleError(error);
        }
      }
    }
    async function getCategoryDetails() {
      if (slug1) {
        try {
          const res = await api.get(`/api/v1/category/slug/${slug1}`);
          if (res && res.data) {
            setCategory(res.data);
          }
        } catch (error) {
          consoleError(error);
        }
      }
    }
    async function getAllData() {
      try {
        await Promise.all([getCategoryDetails(), getSubCategoryDetails()]);
      } catch (error) {
        consoleError(error);
      } finally {
        setCatLoading(false);
        setPageLoading(false);
      }
    }
    getAllData();
  }, [slug1, slug2, setCategory, setSubCategory]);

  const searchProductHandler = (event) => {
    setSearchProduct(event.target.value);
  };

  const filterFromSearchBar =
    subCategory &&
    subCategory.products &&
    subCategory.products.filter((product) => {
      return (
        product.enabled &&
        product.name.toLowerCase().indexOf(searchProduct.toLowerCase()) !== -1
      );
    });

  return (
    <>
      <div className="container-fluid">
        <div className="row d-flex justify-content-center align-items-center ">
          {catLoading
            ? null
            : category &&
              category.subcategory &&
              category.subcategory.map((item, index) => (
                <React.Fragment key={index}>
                  <CategoryPageProductHeading
                    name={transName(item)}
                    redirectLink={`/category/${category.slug}/${item.slug}`}
                  />
                </React.Fragment>
              ))}
        </div>
      </div>
      {pageLoading ? (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <PageSpinner />
        </div>
      ) : category && subCategory ? (
        <>
          <BreadcrumbComponent
            from="Home"
            fromHref="/"
            to={transName(category)}
            toHref={`/category/${category.slug}`}
            thirdPage={transName(subCategory)}
            thirdPageHref={`/category/${category.slug}/${subCategory.slug}`}
          />

          {/* FILTER STARTS */}
          {/* <div className="container-fluid ">
            <div className="row searchAndFilterBar d-flex justify-content-center  align-items-center">
              <div className="col-lg-6 col-md-6 col-12 d-flex justify-content-center align-items-center">
                <div className="searchBoxMainDiv col-lg-9 col-md-9 col-12 d-flex justify-content-center align-items-center">
                  <input
                    type="text"
                    name="searchProduct"
                    className="searchBarProductPage col-lg-11 col-md-11 col-11"
                    placeholder="Search Product"
                    value={searchProduct}
                    onChange={searchProductHandler}
                  />
                  <div>
                    <span
                      className="input-group-text bg-white searchIcon "
                      id="basic-addon1"
                    >
                      <i className="fas fa-search" />
                    </span>
                  </div>
                </div>
              </div>
              </div>
          </div> */}
          {/* Filter Ends */}

          {/* <div className="col-lg-2  col-md-2 col-12  d-flex justify-content-center align-items-center ">
                Sort By:
                <Dropdown className="m-1">
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    Filter
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">
                      Popular item first
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Popular item second
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      Popular item third
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div> */}

          {/* FILTER ENDS */}

          {subCategory &&
          subCategory.products &&
          subCategory.products.length > 0 ? (
            <>
              {/* Filter Starts */}
              <div className="container-fluid ">
                <div className="row searchAndFilterBar d-flex justify-content-center  align-items-center">
                  <div className="col-lg-6 col-md-6 col-12 d-flex justify-content-center align-items-center">
                    <div className="searchBoxMainDiv col-lg-9 col-md-9 col-12 d-flex justify-content-center align-items-center">
                      <input
                        type="text"
                        name="searchProduct"
                        className="searchBarProductPage col-lg-11 col-md-11 col-11"
                        placeholder="Search Product"
                        value={searchProduct}
                        onChange={searchProductHandler}
                      />
                      <div>
                        <span
                          className="input-group-text bg-white searchIcon "
                          id="basic-addon1"
                        >
                          <i className="fas fa-search" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Filter Starts */}
              <div className="container-fluid d-flex   MainContainer">
                <div className="row mt-3 p-0 col-lg-12 col-md-12 col-12 ">
                  <div className="col-lg-12  col-md-12 col-12 d-flex productListDiv">
                    {/* {consoleLog(`length Is: ${filterFromSearchBar.length}`)} */}
                    {filterFromSearchBar.length <= 0 ? (
                      <div className="container-fluid d-flex justify-content-center align-items-center">
                        <div className="d-flex flex-column justify-content-center align-items-center mt-3">
                          <div className="text-center text-muted mb-2">
                            <h4>Sorry products not available</h4>
                          </div>
                          <div className="d-flex justify-content-center align-items-center">
                            <img
                              className="img-fluid"
                              src={PNAImage}
                              alt="Products Not Found"
                              style={{ height: "300px" }}
                            />
                          </div>
                        </div>
                      </div>
                    ) : searchProduct ? (
                      filterFromSearchBar &&
                      filterFromSearchBar.map((product) => (
                        <ProductSection product={product} />
                      ))
                    ) : (
                      subCategory &&
                      subCategory.products &&
                      subCategory.products
                        .filter((product) => product.enabled)
                        .map((product) => <ProductSection product={product} />)
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="d-flex flex-column justify-content-center align-items-center mt-3">
              <div className="text-center text-muted mb-2">
                <h4>Products not available</h4>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <img
                  className="img-fluid"
                  src={PNAImage}
                  alt="Products Not Found"
                  style={{ height: "300px" }}
                />
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="container-fluid">
          <div className="row d-flex justify-content-center align-items-center ">
            <div className="text-center">
              {!category ? <h3>{`Category "${slug1}" Not found.`}</h3> : null}
              {!subCategory ? (
                <h3>{`SubCategory "${slug2}" Not found.`}</h3>
              ) : null}
              <h4>Please check from the list above.</h4>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default SubCategoryPage;
