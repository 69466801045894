import React from "react";
import "../../scss/include/orderSummary.scss";

const OrderSummeryModule = () => {
  return (
    <>
      <div className="col-lg-3 col-md-3 col-12   d-flex justify-content-center align-items-center flex-column">
        {/* Please Select the payment Methods for further process */}
        <span style={{ textDecoration: "underline" }}>ORDER SUMMARY</span>
        <div className="container-fluid mt-2">
          {/* Product LIST STARTS */}
          <div className="row">
            <div className="col-lg-9 col-md-9 col-7 ">Agropure Pulses</div>
            <div className="col-lg-3 col-md-3 col-5 ">$138</div>
          </div>

          <div className="row">
            <div className="col-lg-9 col-md-9 col-7 ">Organic Corn Dalia</div>
            <div className="col-lg-3 col-md-3 col-5 ">$88</div>
          </div>

          <div className="row">
            <div className="col-lg-9 col-md-9 col-7 ">Agropure Pulses</div>
            <div className="col-lg-3 col-md-3 col-5 ">$138</div>
          </div>

          <div className="row">
            <div className="col-lg-9 col-md-9 col-7 ">Organic Corn Dalia</div>
            <div className="col-lg-3 col-md-3 col-5 ">$88</div>
          </div>
          {/* Product LIST ENDS */}

          {/* SERVICE TAX AND VAT CALCULATION */}
          <div className="row serviceDiv">
            <div className="col-lg-9 col-md-9 col-7 ">Service Tax</div>
            <div className="col-lg-3 col-md-3 col-5 ">+$12</div>
          </div>
          <div className="row vatDiv">
            <div className="col-lg-9 col-md-9 col-7 ">VAT</div>
            <div className="col-lg-3 col-md-3 col-5 ">+$10</div>
          </div>
          {/* SERVICE TAX AND VAT CALCULATION */}

          {/* TOTAL CALCULATION */}
          <div className="row  mb-3 ">
            <div className="col-lg-9 col-md-9 col-7 ">Total</div>
            <div className="col-lg-3 col-md-3 col-5  font-weight-bold">
              <span style={{ color: "#21920f" }}>$248</span>
            </div>
          </div>
          {/* TOTAL CALCULATION */}

          {/* Schedule Delivery Starts here */}
          <div className="row  d-flex justify-content-end   ">
            <div className="col-lg-12 col-md-12 col-12  d-flex justify-content-end">
              <a
                href="#scheduleDelivery"
                style={{ color: "black", textDecoration: "underline" }}
              >
                Schedule delivery
              </a>
            </div>
          </div>
          {/* Schedule Delivery ENDS here */}

          {/* Schedule Delivery Starts here */}
          <div className="row  d-flex justify-content-end  mt-2 ">
            <div className="col-lg-12 col-md-12 col-12  d-flex justify-content-center align-items-center">
              <button className="orderConfirmBtn p-2 font-weight-bold">
                CONFIRM ORDER
              </button>
            </div>
          </div>
          {/* Schedule Delivery ENDS here */}
        </div>
      </div>
    </>
  );
};
export default OrderSummeryModule;
