import React, { useContext, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "../../css/owl.theme.default.css";
import "../../scss/include/brandCard.scss";
import { BrandContext } from "../Context/Brand";
import api from "../../services/api";
import { imageUrl } from "../../utils/helper";
// import LazyLoad from "react-lazyload";
import useTransPara from "../hooks/useTransPara";

const BrandCard = () => {
  const { brand, setBrand } = useContext(BrandContext);
  const transName = useTransPara("name");
  const transDescription = useTransPara("description");
  useEffect(() => {
    async function getBrand() {
      const brandRes = await api.get("/api/v1/brands");
      if (brandRes && brandRes.data) {
        setBrand(brandRes.data);
      }
    }
    getBrand();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [brandState] = useState({
    option: {
      items: 4,
      loop: true,
      dots: true,
      nav: false,
      margin: 20,
      autoplay: true,
      autoplayTimeout: 5000,
      autoplaySpeed: 1000,
      autoplayHoverPause: true,
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 2,
          margin: 0,
        },
        992: {
          items: 3,
        },
        1200: {
          items: 4,
        },
      },
    },
  });

  return (
    <div className="container-fluid">
      {brand && Array.isArray(brand) && brand.length > 0 ? (
        <OwlCarousel
          className="owl-theme brand"
          id="slider2"
          {...brandState.option}
        >
          {brand.map((branditem) => {
            return (
              <div key={branditem._id}>
                <a
                  href={`/brand/${branditem.slug}`}
                  className="block"
                  tabIndex="0"
                >
                  <div className="row">
                    <div className="col-md-4 img_holder clearfix">
                      {/* <LazyLoad> */}
                      <img
                        className="w-100"
                        src={imageUrl(branditem.logo)}
                        loading="lazy"
                        alt="brand_logo"
                      />
                      {/* </LazyLoad> */}
                    </div>
                    <div className="col-md-8 text_holder">
                      <p>
                        {transName(branditem)} <br />
                        <span className="saving">
                          {transDescription(branditem)}
                        </span>
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            );
          })}
        </OwlCarousel>
      ) : null}
    </div>
  );
};
export default BrandCard;
