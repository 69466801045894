import React, { createContext, useEffect, useState } from "react";
import api from "../../services/api";
import { consoleError } from "../../utils/helper";

export const BhavikaContext = createContext();

const BhavikaContextProvider = (props) => {
  const [loginShow, setLoginShow] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [topPicks, setTopPicks] = useState([]);
  const [mangoes, setMangoes] = useState([]);
  const [lang, setLang] = useState("en");
  const [sliders, setSliders] = useState([]);

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("userData"))
  );

  useEffect(() => {
    async function getAuthStatus() {
      if (localStorage.getItem("accessToken")) {
        try {
          const res = await api.get("/api/v1/user/profile");
          if (res && res.ok) {
            console.log(res);
            localStorage.setItem("userData", JSON.stringify(res.data));
            setUser(res.data);
            setIsAuthenticated(true);
          }
        } catch (error) {
          consoleError(error);
          setIsAuthenticated(false);
        }
      } else setIsAuthenticated(false);
    }
    getAuthStatus();
  }, []);

  useEffect(() => {
    const localLang = localStorage.getItem("lang");
    setLang(localLang === "hk" ? "hk" : "en");
  }, []);

  return (
    <BhavikaContext.Provider
      value={{
        loginShow,
        setLoginShow,
        blogs,
        setBlogs,
        topPicks,
        mangoes,
        setMangoes,
        setTopPicks,
        lang,
        setLang,
        sliders,
        setSliders,
        isAuthenticated,
        setIsAuthenticated,
        user,
        setUser,
      }}
    >
      {props.children}
    </BhavikaContext.Provider>
  );
};

export default BhavikaContextProvider;
