import React, { useContext, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "../../css/owl.theme.default.css";
import "../../scss/include/featuredProudctCard.scss";
import { FeaturedContext } from "../Context/Featured";
import api from "../../services/api";

import { imageUrl } from "../../utils/helper";
import { consoleError } from "../../utils/helper";
import useTransPara from "../hooks/useTransPara";

// import LazyLoad from 'react-lazyload';

const FeaturedProductCard = () => {
  const { featured, setFeatured } = useContext(FeaturedContext);
  const transName = useTransPara("name");
  useEffect(() => {
    async function getFeatured() {
      try {
        const featuredRes = await api.get("/api/v1/featured");
        if (featuredRes && featuredRes.data) {
          setFeatured(featuredRes.data);
        }
      } catch (error) {
        consoleError(error);
      }
    }
    getFeatured();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [featureState] = useState({
    option: {
      items: 4,
      loop: true,
      dots: true,
      nav: false,
      margin: 20,
      autoplayHoverPause: true,
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 2,
          margin: 0,
        },
        992: {
          items: 3,
        },
        1200: {
          items: 4,
        },
      },
    },
  });
  return (
    <div className="container-fluid feature-section">
      {featured && Array.isArray(featured) && featured.length > 0 ? (
        <OwlCarousel
          className="owl-theme featured"
          id="slider3"
          {...featureState.option}
        >
          {featured.map((item) => {
            let url = "/#";
            if (item.featured === "product") {
              if (item.product) {
                url = `/product/${item.slug}`;
              }
            } else if (item.featured === "category") {
              if (item.category) {
                url = `/category/${item.category.slug}`;
              }
            } else if (item.featured === "brand") {
              if (item.brand) {
                url = `/brand/${item.brand.slug}`;
              }
            } else if (item.featured === "subcategory") {
              if (item.subcategory) {
                const category =
                  item.subcategory.category && item.subcategory.category.slug;
                url = `/category/${category}/${item.subcategory.slug}`;
              }
            }
            return (
              <div key={item._id}>
                <a href={url} className="text-center">
                  <div className="card">
                    <div className="brand-block card-img-top">
                      {/* <LazyLoad> */}
                      <img
                        className="mx-auto d-block"
                        src={imageUrl(item.banner)}
                        loading="lazy"
                        alt="featured_banner"
                      />
                      {/* </LazyLoad> */}
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">{transName(item)}</h5>
                    </div>
                  </div>
                </a>
              </div>
            );
          })}
        </OwlCarousel>
      ) : null}
    </div>
  );
};
export default FeaturedProductCard;
