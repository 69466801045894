import React from "react";
// import BreadcrumbComponent from "../CustomComponents/BreadcrumbComponent";
// import PaymentMethodCard from "../CustomComponents/PaymentMethodCard";
// import ShopByHeading from "../CustomComponents/ShopByHeading";
import "../../scss/include/creditCardpayment.scss";
// import OrderSummeryModule from "./OrderSummeryMoule";
// import OurService from "./OurService";

const CreditCardPayment = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 col-lg-8 col-xl-9 col-sm-12 col-12">
            <form>
              <div className="form-group">
                <label htmlFor="cardname">NAME ON CARD</label>
                <input type="text" className="form-control" id="cardname" />
              </div>
              <div className="form-group">
                <label htmlFor="cardnumber">CARD NUMBER</label>
                <input
                  type="cardnumber"
                  className="form-control"
                  id="cardnumber"
                  placeholder="0000-0000-0000-0000"
                />
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-12 ">
                  <label htmlFor="cardmonth">MONTH</label>
                  <select id="cardmonth" className="form-control">
                    <option value="">Select Month</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                  </select>
                </div>
                <div className="col-lg-4 col-md-4 col-12 ">
                  <label htmlFor="cardyear">YEAR</label>
                  <select id="cardyear" className="form-control">
                    <option value="">Choose...</option>
                    <option>...</option>
                  </select>
                </div>
                <div className="col-lg-4 col-md-4 col-12 ">
                  <label htmlFor="cardcvv">CVV</label>
                  <input
                    type="number"
                    className="form-control"
                    id="cardcvv"
                    // placeholder="0000-0000-0000-0000"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreditCardPayment;
