import React, { useEffect, useState } from "react";
import "../../scss/include/userDetailsComponents.scss";
import api from "../../services/api";
import { consoleError } from "../../utils/helper";

const UserDetailsComponents = ({ data }) => {
  const [shippingAddresses, setShippingAddresses] = useState([]);

  useEffect(() => {
    async function getShippingAddress() {
      try {
        const res = await api.get("/api/v1/user/shipping/address");
        if (res && res.ok) {
          if (res.data && Array.isArray(res.data))
            setShippingAddresses(res.data);
        }
      } catch (error) {
        consoleError(error);
      }
    }
    getShippingAddress();
  }, []);
  return (
    <div className="col-lg-9 col-md-9 col-12 p-0 d-flex  flex-column userDetailsComponentsMainDiv">
      {/* User Profile */}
      <div
        className=" d-flex flex-column   userDetailsDiv"
        style={{ borderBottom: "1px grey solid " }}
      >
        <div
          className="col-lg-12 col-md-12 col-12  mt-1"
          style={{ padding: "0.5rem" }}
        >
          <span className="font-weight-bold">User Profile</span>
        </div>
        <div className="details  d-flex subDiv  mt-3 mb-3">
          <div className="col-lg-4 col-md-4 col-12 ">
            <span>Email: {data.email}</span>
          </div>
          <div className="col-lg-4 col-md-4 col-12 ">
            <span>Mobile Number: +852-{data.phone}</span>
          </div>
          {data.address && (
            <div className="col-lg-4 col-md-4 col-12 ">
              <span>Address: {data.address}</span>
            </div>
          )}
        </div>
      </div>
      {/* User Profile Ends */}

      {/* Delivery Address */}
      <div
        className=" d-flex flex-column  userDetailsDiv"
        // style={{ borderBottom: "1px grey solid " }}
      >
        <div className="col-lg-12 col-md-12 col-12 mt-1">
          <span className="font-weight-bold">Delivery Address</span>
        </div>
        <div>
          {shippingAddresses &&
          Array.isArray(shippingAddresses) &&
          shippingAddresses.length > 0 ? (
            shippingAddresses.map((addr, index) => (
              <div className="details  d-flex subDiv mt-3 mb-3" key={index}>
                <div className="col-lg-3 col-md-3 col-12  ">
                  <span>Full Name: {addr.name}</span>
                </div>
                <div className="col-lg-3 col-md-3 col-12  ">
                  <span>Address: {addr.address}</span>
                </div>
                <div className="col-lg-3 col-md-3 col-12  ">
                  <span>Region: {addr.region}</span>
                </div>
                <div className="col-lg-3 col-md-3 col-12  ">
                  <span>Mobile Number: {addr.phone}</span>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center text-muted m-2">
              <span>No delevery address</span>
            </div>
          )}
        </div>
      </div>
      {/* Delivery Address Ends */}

      {/* Payment Details */}
      {/*<div className=" d-flex flex-column  userDetailsDiv">*/}
      {/*  <div className="col-lg-12 col-md-12 col-12 mt-1">*/}
      {/*    <span className="font-weight-bold">Payment Details</span>*/}
      {/*  </div>*/}
      {/*  <div className="details  d-flex subDiv mt-3 mb-3 ">*/}
      {/*    <div className="col-lg-4 col-md-4 col-12  ">*/}
      {/*      <span>Pay Me: 1234567890</span>*/}
      {/*    </div>*/}
      {/*    <div className="col-lg-4 col-md-4 col-12 ">*/}
      {/*      <span>Pay Pal: 1234567890</span>*/}
      {/*    </div>*/}
      {/*    <div className="col-lg-4 col-md-4 col-12 ">*/}
      {/*      <span>Credit Card:</span>*/}
      {/*      <br />*/}
      {/*      <span>Master Card Details</span>*/}
      {/*      <br />*/}
      {/*      <span>Master Card Details</span>*/}
      {/*      <br />*/}
      {/*      <span>Master Card Details</span>*/}
      {/*      <br />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/* Payment Details Ends */}
    </div>
  );
};
export default UserDetailsComponents;
