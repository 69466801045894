import React from "react";
import { Link } from "react-router-dom";
import "../../scss/include/headingProductsForCategoryPage.scss";
import { imageUrl } from "../../utils/helper";
// import img from "../../assets/Best Buy/wai wai.png";

const HeadingProductsForCategoryPage = (props) => {
  return (
    <>
      <div className="col-lg-4 col-md-4 col-10   p-3 d-flex justify-content-center align-items-center border border-grey">
        <div className="w-50   d-flex flex-column infoDiv">
          <div className="w-100 h-75 ">
            <span className="text-muted ">{props.heading}</span>
            <p className="text-success font-weight-bold">{props.productName}</p>
          </div>
          <div className="w-100 h-25 ">
            {">"} <Link to={props.redirectLink}>{props.hrefName}</Link>
          </div>
        </div>
        <div className="w-50 ">
          <img
            src={imageUrl(props.banner)}
            className="img-fluid"
            alt="products"
          />
        </div>
      </div>
    </>
  );
};
export default HeadingProductsForCategoryPage;
