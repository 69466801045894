import React, { createContext, useState } from "react";
// import api from "../../services/api";

export const PromotionContext = createContext();

const PromotionContextProvider = (props) => {
  const [promotion, setPromotion] = useState([]);

  return (
    <PromotionContext.Provider value={{ promotion, setPromotion }}>
      {props.children}
    </PromotionContext.Provider>
  );
};

export default PromotionContextProvider;
