import React from "react";
import BreadcrumbComponent from "../CustomComponents/BreadcrumbComponent";
import "../../scss/include/_aboutUs.scss";
import useTrans from "../hooks/useTrans";
import RealTasteOfIndiaMenu from "./RealTasteOfIndiaMenu";
import RealTasteOfIndiaSidebar from "./RealTasteOfIndiaSidebar";

const RealTasteOfIndia = () => {
  const trans = useTrans();
  return (
    <div className={"real-taste-of-india p-0"}>
      <BreadcrumbComponent
        from={trans.home}
        fromHref="/"
        to={trans.realTasteOfIndia}
        toHref="/real-taste-of-india"
      />
      <div className="row">
        <div className="col-md-9 cpl-sm-12">
          <RealTasteOfIndiaMenu />
        </div>
        <div className="col-md-3 cpl-sm-12">
          <RealTasteOfIndiaSidebar />
        </div>
      </div>
      {/* <OurStaff /> */}
      {/* <DownloadOurAPpImgComponent /> */}
    </div>
  );
};
export default RealTasteOfIndia;
