import React from "react";
import tc1 from "../../assets/OurStores/tc1.jpg";
// import tc2 from "../../assets/OurStores/tc2.jpg";
// import tc3 from "../../assets/OurStores/tc3.jpg";
import tc4 from "../../assets/OurStores/tc4.jpg";
import tc5 from "../../assets/OurStores/tc5.jpg";
import tc6 from "../../assets/OurStores/tc6.jpg";
import tc7 from "../../assets/OurStores/tc7.jpg";

import tko1 from "../../assets/OurStores/tko1.jpeg";
import tko2 from "../../assets/OurStores/tko2.jpeg";
import tko3 from "../../assets/OurStores/tko3.jpeg";
import tko4 from "../../assets/OurStores/tko4.jpeg";
import tko5 from "../../assets/OurStores/tko5.jpeg";
import tko6 from "../../assets/OurStores/tko6.jpeg";
import tko7 from "../../assets/OurStores/tko7.jpeg";
import tko8 from "../../assets/OurStores/tko8.jpeg";
import tko9 from "../../assets/OurStores/tko9.jpeg";

// import LazyLoad from "react-lazyload";
import useTrans from "../hooks/useTrans";

const OurStores = () => {
  const trans = useTrans();
  return (
    <div className="container-fluid">
      <h6 className="title font-weight-bold">{trans.ourStores}</h6>
      <div className="row align-items-center">
        <div className="col-md-6 col-12  d-flex imgDivOfStore">
          <img src={tc1} alt="store_one" className="img-fluid" />
          <img src={tc7} alt="store_one" className="img-fluid" />
          <img src={tc6} alt="store_one" className="img-fluid" />
          {/* <img src={tc3} alt="store_one" className="img-fluid" /> */}
          <img src={tc4} alt="store_one" className="img-fluid" />
          <img src={tc5} alt="store_one" className="img-fluid" />
        </div>
        <div className="col-md-6 col-12 story-desc" id={"store1"}>
          <h6 className="font-weight-bold title">Bhavika Store, Tung Chung</h6>
          <p>Shop G03, G/F, T-Bay, 9 Yi Tung Road, Tung Chung, Hong Kong</p>
          <p>Working Days: Monday to Sunday</p>
          <p>Time: 9:00 AM to 10:30 PM</p>
        </div>
      </div>
      <div className="row align-items-center mt-2">
        <div className="col-md-6 col-12 story-desc" id={"store2"}>
          <h6 className="font-weight-bold title">
            Bhavika Store, Tseung Kwan O
          </h6>
          <p>
          Shop G08, G/F Capri Place, 33 Tong Yin Street, Tseung Kwan O, N.T., Hong Kong
          </p>
          <p>Working Days: Monday to Sunday</p>
          <p>Time: 10:00 AM to 9:00 PM</p>
        </div>
        <div className="col-md-6 col-12  d-flex imgDivOfStore">
          <img src={tko1} alt="store_two" className="img-fluid" />
          <img src={tko2} alt="store_two" className="img-fluid" />
          <img src={tko3} alt="store_two" className="img-fluid" />
          <img src={tko4} alt="store_two" className="img-fluid" />
          <img src={tko5} alt="store_two" className="img-fluid" />
          <img src={tko6} alt="store_two" className="img-fluid" />
          <img src={tko7} alt="store_two" className="img-fluid" />
          <img src={tko8} alt="store_two" className="img-fluid" />
          <img src={tko9} alt="store_two" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};
export default OurStores;
