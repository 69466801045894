import React from 'react';

const RealDeliveryAddress = ({setSelectedAddress}) =>{
    setSelectedAddress('Four Points and Sheraton Hotel Tung Chung');
    return(
        <>
                <div className="row my-4">
          <div className="col-12">
            <h4>
              Delivery Address
              <div className="borderBottom"></div>
            </h4>
          </div>
        </div>
         <div className="table-responsive">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Address</th>
              </tr>
            </thead>
            <tbody>
                <tr >
                  <td>We Only Deliver At Four Points and Sheraton Hotel Tung Chung</td>
                </tr>
            </tbody>
          </table>
        </div>
        </>
    )
}
export default RealDeliveryAddress;