import React, { useState } from "react";
import ProductSection from "./ProductSection";
import "../../scss/include/category.scss";
import PNAImage from "../../assets/Others/Product not found vector.svg";

export default function ProductListWithSearch({ productList, discount_type, discount, promotion_on }) {
  const [searchProduct, setSearchProduct] = useState("");

  const searchProductHandler = (event) => {
    setSearchProduct(event.target.value);
  };
  // const productSearchHandler = () => {
  //   alert(`Searched Product is: ${searchProduct}`);
  // };

  const filterFromSearchBar = productList.filter((product) => {
    return (
      product.name.toLowerCase().indexOf(searchProduct.toLowerCase()) !== -1
    );
  });
  return (
    <div>
      {/* <div className="d-flex justify-content-center align-items-center">
        <div className="col-lg-6 col-md-6 col-12   d-flex justify-content-center align-items-center">
          <div className="searchBoxMainDiv col-lg-9 col-md-9 col-12 d-flex justify-content-center align-items-center">
            <input
              type="text"
              name="searchProduct"
              className="searchBarProductPage col-lg-11 col-md-11 col-11"
              placeholder="Search Product"
              value={searchProduct}
              onChange={searchProductHandler}
            />
            <div
              // onClick={productSearchHandler}
              style={{ cursor: "pointer" }}
            >
              <span
                className="input-group-text bg-white searchIcon "
                id="basic-addon1"
              >
                <i className="fas fa-search" />
              </span>
            </div>
          </div>
        </div>
      </div> */}

      {productList && productList.length > 0 ? (
        <>
          <div className="d-flex justify-content-center align-items-center">
            <div className="col-lg-6 col-md-6 col-12   d-flex justify-content-center align-items-center">
              <div className="searchBoxMainDiv col-lg-9 col-md-9 col-12 d-flex justify-content-center align-items-center">
                <input
                  type="text"
                  name="searchProduct"
                  className="searchBarProductPage col-lg-11 col-md-11 col-11"
                  placeholder="Search Product"
                  value={searchProduct}
                  onChange={searchProductHandler}
                />
                <div
                  // onClick={productSearchHandler}
                  style={{ cursor: "pointer" }}
                >
                  <span
                    className="input-group-text bg-white searchIcon "
                    id="basic-addon1"
                  >
                    <i className="fas fa-search" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid d-flex   MainContainer">
            <div className="row mt-3 p-0 col-lg-12 col-md-12 col-12">
              <div className="col-lg-12  col-md-12 col-12 d-flex productListDiv">
                {filterFromSearchBar.length <= 0 ? (
                  <div className="container-fluid d-flex justify-content-center align-items-center">
                    <div className="d-flex flex-column justify-content-center align-items-center mt-3">
                      <div className="text-center text-muted mb-2">
                        <h4>Sorry products not available</h4>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        <img
                          className="img-fluid"
                          src={PNAImage}
                          alt="Products Not Found"
                          style={{ height: "300px" }}
                        />
                      </div>
                    </div>
                  </div>
                ) : searchProduct ? (
                  filterFromSearchBar.map((product, index) => (
                    <ProductSection product={product} key={index} />
                  ))
                ) : (
                  productList.map((product, index) => (
                    <ProductSection product={product} key={index} discount_type={discount_type} discount={discount} promotion_on={promotion_on}/>
                  ))
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center mt-3">
          <div className="text-center text-muted mb-2">
            <h4>Products not available</h4>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <img
              className="img-fluid"
              src={PNAImage}
              alt="Products Not Found"
              style={{ height: "300px" }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
