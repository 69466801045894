import React from "react";

export default function InputErrorMsg(props) {
  return (
    <div>
      <small style={{ color: "red" }} className="form-text ml-1">
        {props.children}
      </small>
    </div>
  );
}
