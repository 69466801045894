import React from "react";

function PageCenter({ children }) {
  return (
    <div className="d-flex justify-content-center align-items-center mt-5 mb-5">
      {children}
    </div>
  );
}

export default PageCenter;
