import qs from "qs";

export const CLIENT_ID = "";
export const CLIENT_SECRET = "";
export const SIGNING_KEY_ID = "";
export const SIGNING_KEY_SECRET = "";

// API Base URL
export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "https://bhavika.online";

export const GOOGLE_APP_ID =
  "591172236489-etkud7ketv4vglnd8tkj390k5qntvv79.apps.googleusercontent.com" ||
  process.env.REACT_APP_GOOGLE_APP_ID ||
  "98652465412-77gcvaebgtv4snk4bouipm5lju34fsgq.apps.googleusercontent.com";

export const GOOGLE_REDIRECT_URI =
  process.env.REACT_APP_GOOGLE_REDIRECT_URI ||
  "https://bhavika.online/authenticate/google";
export const FACEBOOK_APP_ID =
  process.env.REACT_APP_FACEBOOK_APP_ID || "338088477418962";

export const FACEBOOK_REDIRECT_URI =
  process.env.REACT_APP_FACEBOOK_REDIRECT_URI ||
  "https://bhavika.online/authenticate/facebook";

const google_scopes = [
  "https://www.googleapis.com/auth/userinfo.email",
  "https://www.googleapis.com/auth/userinfo.profile",
];
const google_query = qs.stringify({
  client_id: GOOGLE_APP_ID,
  redirect_uri: GOOGLE_REDIRECT_URI,
  scope: google_scopes.join(" "),
  response_type: "code",
  access_type: "offline",
  prompt: "consent",
});

const facebook_query = qs.stringify({
  client_id: FACEBOOK_APP_ID,
  redirect_uri: FACEBOOK_REDIRECT_URI,
  scope: ["email"].join(","), // comma seperated string
  response_type: "code",
  auth_type: "rerequest",
  display: "popup",
});
export const GOOGLE_LOGIN_URL = `https://accounts.google.com/o/oauth2/v2/auth?${google_query}`;
export const FACEBOOK_LOGIN_URL = `https://www.facebook.com/v4.0/dialog/oauth?${facebook_query}`;

export const UPLOAD_URL =
  process.env.REACT_APP_UPLOAD_URL || "https://bhavika.online/api";

export const aws_region = process.env.AWS_REGION || "us-east-2";
export const aws_pool_id =
  process.env.AWS_POOL_ID || "us-east-2:7b0e662c-8c03-4f86-9382-ea99d71fe42d";
