import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "../../scss/include/recentOrdersComponents.scss";
import api from "../../services/api";
import { consoleError } from "../../utils/helper";

const RecentOrdersComponents = ({ myOrders, setMyOrders }) => {
  // const options = {
  //   weekday: "long",
  //   year: "numeric",
  //   month: "long",
  //   day: "numeric",
  // };

  const history = useHistory();
  useEffect(() => {
    async function getMyOrders() {
      try {
        const res = await api.get("/api/v1/user/order");
        if (res && res.ok) {
          if (res.data && Array.isArray(res.data)) setMyOrders(res.data);
        }
      } catch (error) {
        consoleError(error);
      }
    }
    getMyOrders();
  }, [setMyOrders]);
  return (
    <>
      <div className="col-lg-12 col-md-12 col-12 p-0 recentOrdersMainDiv">
        <div
          className="col-lg-12 col-md-12 col-12"
          style={{ padding: "0.5rem" }}
        >
          <span className="font-weight-bold">Recent Orders</span>
        </div>

        <div className="col-lg-12 col-md-12 col-12 table-responsive">
          {/* Table starts */}
          <table className="table table-striped cart-table table-hover">
            <thead>
              <tr>
                <th className={"text-center"}>Order ID</th>
                <th className={"text-left"}>Order Date</th>
                <th className={"text-left"}>Items</th>
                <th className={"text-center"}>Total</th>
                <th className={"text-center"}>Payment</th>
                <th className="text-center">Method</th>
                <th className={"text-center"}>Status</th>
                <th className="text-center">Actions</th>
                {/* <th style={{ width: "10%" }}></th> */}
              </tr>
            </thead>
            <tbody>
              {myOrders.length > 0 ? (
                myOrders.map((order, index) => (
                  <tr
                    key={index}
                    // onClick={() => history.push(`/myorders/${order._id}`)}
                  >
                    <td className={"text-center"}>
                      <span>{order._id}</span>
                    </td>
                    <td className={"text-left"}>
                      <span>
                        {new Date(order.createdAt).toLocaleDateString("en-CA")}
                      </span>
                    </td>
                    <td className={"text-left"}>
                      {order.orderItems.map((item, index) => (
                        <React.Fragment key={index}>
                          <span>{item.name}</span>
                          <br />
                        </React.Fragment>
                      ))}
                    </td>
                    <td className={"text-center"} style={{ width: "10%" }}>
                      <span> HKD ${order.totalPrice}</span>
                    </td>
                    <td className={"text-center"} style={{ width: "10%" }}>
                      <span> {order.paidStatus}</span>
                    </td>
                    <td className={"text-center"} style={{ width: "10%" }}>
                      <span> {order.paymentMethod}</span>
                    </td>
                    <td className={"text-center"} style={{ width: "10%" }}>
                      <span>{order.status}</span>
                    </td>
                    <td className={"text-center"} style={{ width: "10%" }}>
                      {order.paidStatus === "pending" ? (
                        <Button
                          size="sm"
                          className="viewOrder"
                          variant="info"
                          onClick={() => history.push(`/myorders/${order._id}`)}
                        >
                          Pay <i className="fa fa-money-bill " />
                        </Button>
                      ) : (
                        <Button
                          size="sm"
                          variant="info"
                          onClick={() => history.push(`/myorders/${order._id}`)}
                        >
                          View <i className="fa fa-eye" />
                        </Button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={8} className="text-center">
                    No orders
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {/* Table ends */}
        </div>
      </div>
    </>
  );
};
export default RecentOrdersComponents;
