import React, { useContext, useEffect, useState } from "react";
import ShopByHeading from "../CustomComponents/ShopByHeading";
import ShopByCategoryCard from "../CustomComponents/ShopByCategoryCard";
import "../../scss/include/carousel.scss";
import CarouselComponent from "../CustomComponents/Carousel";

import DealsYouCannotMissCard from "../CustomComponents/DealsYouCannotMissCard";
import BestBuyCard from "../CustomComponents/BestBuyCard";
// import Footer from "./Footer";
import DownloadOurAPpImgComponent from "../CustomComponents/DownloadOurAPpImgComponent";
import BrandCard from "../CustomComponents/BrandCard";
import FeaturedProductCard from "../CustomComponents/FeaturedProductCard";
import { CategoryContext } from "../Context/Category";
import api from "../../services/api";
import RandomProductCard from "../CustomComponents/RandomProductCard";
import useTransPara from "../hooks/useTransPara";
import useTrans from "../hooks/useTrans";
import Modal from "react-bootstrap/Modal";
import video from "../../assets/video.mp4";
import minorImg from '../../assets/img/liquor_law.jpg';
import {
  faPlayCircle,
  faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TestimonialsCard from "../CustomComponents/TestimonialsCard";
const Home = () => {
  const trans = useTrans();
  const { category, setCategory } = useContext(CategoryContext);
  const [filtercategory, setFilterCategory] = useState([]);

  const transName = useTransPara("name");
  useEffect(() => {
    async function getCategory() {
      const categoryRes = await api.get("/api/v1/category");
      if (categoryRes && categoryRes.data) {
        setCategory(categoryRes.data);
      }
    }
    getCategory();
  }, [setCategory]);

  //   console.log(category);

  useEffect(() => {
    filterCategoryFunction();
  }, [category]);

  const filterCategoryFunction = () => {
    let data = [...category];
    const popData = data.pop();
    data.unshift(popData);
    setFilterCategory(data);
  };

  const [videoModel, setVideoModel] = useState(false);
  const [minorModal, setMinormodal] = useState(false);

  const handleClose = () => {
    setVideoModel(false);
  };
  const handleShow = () => {
    setVideoModel(true);
  };
  const closeMinorModal = () => {
    setMinormodal(false);
  };

  return (
    <div>
      <CarouselComponent />
      {/* SHOP BY CATEGORY STARTS FROM HERE */}
      <ShopByHeading title={trans.shopByCategory} />
      <div className="col-12 d-flex p-0">
        <div className="col-lg-8 col-12">
          <div className="row container">
            {filtercategory &&
              filtercategory.length > 1 &&
              filtercategory.map((cat) => {
                return (
                  <React.Fragment key={cat._id}>
                    <ShopByCategoryCard
                      heading={transName(cat)}
                      imageName={cat.banner}
                      redirectionLink={`/category/${cat.slug}`}
                    />
                  </React.Fragment>
                );
              })}
          </div>
        </div>
        <div
          className="col-lg-4 col-0 videoWrapperHomePage d-flex justify-content-center "
          onClick={handleShow}
        >
          <video muted width="400" height="400">
            <source src={video} type="video/mp4" />
          </video>
          <div className="playBtn d-flex justify-content-center align-items-center">
            <FontAwesomeIcon
              icon={faPlayCircle}
              style={{ width: "50px", height: "50px", color: "white" }}
            />
            <div className="contentDiv d-flex flex-column">
              <span>Amitji loves</span>
              <span>Bikaji</span>
            </div>
          </div>
        </div>
      </div>
      {/* SHOP BY CATEGORY STARTS FROM HERE */}

      {/*/!* BEST BUY STARTS HERE *!/*/}
      <ShopByHeading title={trans.topPicks} />
      <RandomProductCard />
      {/*/!* BEST BUY ENDS HERE *!/*/}

      {/* SHOP BY BRANDS STARTS HERE */}
      <ShopByHeading title={trans.shopByBrands} />
      <BrandCard />
      {/* SHOP BY BRANDS ENDS HERE */}
      {/*/!* FEATURED PRODUCTS STARTS HERE *!/*/}
      <ShopByHeading title={trans.featured} />
      <FeaturedProductCard />
      {/*/!* FEATURED PRODUCTS ENDS HERE *!/*/}

      {/*/!* DEALS YOU CANNOT MISS STARTS HERE *!/*/}
      <ShopByHeading title={trans.dealsYouCannotMiss} />
      <DealsYouCannotMissCard />
      {/*/!* DEALS YOU CANNOT MISS ENDS HERE *!/*/}

      {/*/!* BEST BUY STARTS HERE *!/*/}
      <ShopByHeading title={trans.bestBuy} />
      <BestBuyCard />
      {/*/!* BEST BUY ENDS HERE *!/*/}
      <div id="bhavika_app"></div>
      <DownloadOurAPpImgComponent />
      {videoModel ? (
        <Modal
          show={videoModel}
          onHide={handleClose}
          className="bikajiVideoModel p-0"
          size="lg"
          centered
        >
          <div className="closeButton" onClick={handleClose}>
            <FontAwesomeIcon
              icon={faTimesCircle}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <Modal.Body className="p-0 d-flex justify-content-center align-items-center">
            {/* <video controls>
              <source src={video} type="video/mp4" />
            </video> */}
            <iframe
              style={{ height: "100%", border: "none" }}
              src="https://www.youtube.com/embed/gA-kAzOu2rw"
            ></iframe>
          </Modal.Body>
        </Modal>
      ) : (
        ""
      )}
      {/* Minor MODAL */}
      <Modal
        size="md"
        show={minorModal}
        onHide={closeMinorModal}
        aria-labelledby="example-modal-sizes-title-lg"
        className="modelMainDiv"
        centered
      >
        <Modal.Body>
          <button
            class="btn btn-outline-secondary btn-circle"
            onClick={closeMinorModal}
            style={{ position: "absolute", top: "-10px", right: "-10px", backgroundColor: "#fff" }}
          >
            <i class="fa fa-times"></i>
          </button>
          <a href="#">
            <img src={minorImg} alt="" style={{ maxWidth: '100%' }} />
          </a>
        </Modal.Body>
      </Modal>


      {/*/!* Testimonials STARTS HERE *!/*/}
      <ShopByHeading title={trans.testimonials} />
      <TestimonialsCard />
      {/*/!* Testimonials ENDS HERE *!/*/}
    </div>
  );
};
export default Home;
