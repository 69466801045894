import React from "react";

const FailedMessage = (props) => {
  return (
    <>
      <div className="alert alert-danger text-center" role="alert">
        {props.msg}
      </div>
    </>
  );
};
export default FailedMessage;
