import React from "react";
import { Card } from "react-bootstrap";
import { useHistory } from "react-router";
import PNAImage from "../../assets/Others/Product not found vector.svg";
import { imageUrl } from "../../utils/helper";
import useTransPara from "../hooks/useTransPara";
import "../../scss/include/brandList.scss";

function BrandList({ brands = [] }) {
  const history = useHistory();
  const transName = useTransPara("name");
  const transDescription = useTransPara("description");
  return (
    <div className="brandList">
      {brands.length > 0 ? (
        brands.map((brand) => (
          <div
            key={brand._id}
            className="col-lg-2 col-md-3 col-sm-6"
            style={{ cursor: "pointer" }}
            onClick={() => history.push(`/brand/${brand.slug}`)}
          >
            <Card className="brandCard">
              <div className="cardImage">
                <Card.Img variant="top" src={imageUrl(brand.logo)}></Card.Img>
              </div>
              <Card.Body>
                <Card.Title className="brandTitle">
                  <h5>{transName(brand)}</h5>
                </Card.Title>
                <Card.Text className="brandText">
                  {transDescription(brand)}
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        ))
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center mt-3">
          <div className="text-center text-muted mb-2">
            <h4>Brands not available</h4>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <img
              className="img-fluid"
              src={PNAImage}
              alt="Brands Not Found"
              style={{ height: "300px" }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default BrandList;
