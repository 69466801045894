import React from "react";
import "../../scss/include/service.scss";
import BreadcrumbComponent from "../CustomComponents/BreadcrumbComponent";

const Service = () => {
  return (
    <>
      <BreadcrumbComponent
        from="Home"
        fromHref="/"
        to="Services"
        toHref="/service"
      />

      <div className="container-fluid serviceMainCardDiv  mb-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12  d-flex flex-column ">
            <span className="serviceHeading mb-1">Say Hello to India</span>
            <span className="serviceDescription ">
              Indian Spices and Masalas are an integral part of any kitchen. The
              spices and masala mixes have found their way into homes across the
              globe. We at bhavika.online bring the authentic and quality Indian
              products right at your doorstep. Combine with fresh vegetables and
              fruits and you have got a one stop shop for your daily needs. We
              also provide Bakery items and Dairy products with Household
              products.
            </span>
          </div>
        </div>
      </div>

      <div className="container-fluid serviceMainCardDiv  mb-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12  d-flex flex-column">
            <span className="serviceHeading mb-1">We Are Here To Help You</span>
            <span className="serviceDescription">
              No distributor knows the Indian Restaurant community better than
              bhavika.online. For years, we have been committed to providing the
              special grains, pulses, spices and ingredients for Asian cuisine.
              Our customers are Hotels, Restaurants, Homes and Deli shops. We
              are one of the very few companies in Hong Kong business segment
              which has direct contact with the Suppliers/Farmers/Factories.
              There is no third party agent involved. No matter what your menu
              selections, we have what you need. We offer a wide range of Asian
              products that bring value to the business and quality to the end
              products.
            </span>
          </div>
        </div>
      </div>

      <div className="container-fluid serviceMainCardDiv  mb-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12  d-flex flex-column ">
            <span className="serviceHeading mb-1">
              Delivery Right At Your Door-Step
            </span>
            <span className="serviceDescription">
              We are aware of the time sensitive nature of the ordering process,
              as well as the many complexities of running a successful
              restaurant or catering business. With the busy lifestyles of a
              modern family, it is difficult to manage household chores in time.
              After doing careful Market analysis and understanding the mindset
              and lifestyle requirements of our Customers, We have decided to go
              ahead with the Online Supermarket concept in Hong Kong. Our aim is
              to provide faster and easy online shopping experience for our
              users. An efficient and optimized delivery system helps us to
              serve our customers better. The home delivery system is developed
              in such a way that the order you place will reach your doorstep
              with minimal time delays. We are dedicated to delivering your
              order when you need it.
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default Service;
