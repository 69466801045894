import React, { useState } from "react";
import BreadcrumbComponent from "../CustomComponents/BreadcrumbComponent";

import "../../scss/include/productListPage.scss";
import "../../scss/include/sideFilter.scss";
import "../../scss/include/productListCard.scss";
import ProductSection from "../CustomComponents/ProductSection";

const ProductListPage = () => {
  const [searchProduct, setSearchProduct] = useState("");

  const [filterState] = useState({
    brandA: "unchecked",
    brandB: "unchecked",
    brandC: "unchecked",
    vol: "",
  });

  // const handleChange = (event) => {
  //   // let isChecked = e.target.checked;

  //   // Object Destructuring
  //   const { name, value } = event.target;

  //   setFilterState((prevValue) => {
  //     return {
  //       ...prevValue,
  //       [name]: value,
  //     };
  //   });
  // };

  // const submitHandler = () => {
  //   alert(
  //     `Brand A is: ${filterState.brandA}, Brand B is: ${filterState.brandB}, and Brand C is: ${filterState.brandC}, and range is ${filterState.vol}`
  //   );
  // };

  const ProductList = [
    {
      id: "1",
      imgName: "Agropure Toor Dal.png",
      productName: "Agropure Toor Dal",
      price: "1.5",
    },
    {
      id: "2",
      imgName: "Agropure Toor Dal.png",
      productName: "Ashirbad atta",
      price: "2",
    },
    {
      id: "3",
      imgName: "Agropure Toor Dal.png",
      productName: "Bengoli Brinjal",
      price: "13",
    },
    {
      id: "4",
      imgName: "Agropure Toor Dal.png",
      productName: "Bourn Born",
      price: "214",
    },
    {
      id: "5",
      imgName: "Agropure Toor Dal.png",
      productName: "Gloves",
      price: "25",
    },
    {
      id: "6",
      imgName: "Agropure Toor Dal.png",
      productName: "Goldsstar shoes",
      price: "61",
    },
    {
      id: "7",
      imgName: "Agropure Toor Dal.png",
      productName: "Hand Sanitizer",
      price: "217",
    },
    {
      id: "8",
      imgName: "Agropure Toor Dal.png",
      productName: "lifeboy",
      price: "218",
    },
    {
      id: "9",
      imgName: "Agropure Toor Dal.png",
      productName: "Old Monk",
      price: "99",
    },
    {
      id: "10",
      imgName: "Agropure Toor Dal.png",
      productName: "Poha",
      price: "100",
    },
    {
      id: "11",
      imgName: "Agropure Toor Dal.png",
      productName: "Rajdhani Besan",
      price: "211",
    },
    {
      id: "12",
      imgName: "Agropure Toor Dal.png",
      productName: "Tokla",
      price: "213",
    },
    {
      id: "13",
      imgName: "Agropure Toor Dal.png",
      productName: "wai wai",
      price: "21.5",
    },
  ];

  const searchProductHandler = (event) => {
    setSearchProduct(event.target.value);
  };
  // const productSearchHandler = () => {
  //   alert(`Searched Product is: ${searchProduct}`);
  // };

  const filterFromSearchBar = ProductList.filter((product) => {
    return (
      product.productName.toLowerCase().indexOf(searchProduct.toLowerCase()) !==
      -1
    );
  });

  const filterFromPrice = ProductList.filter(
    (product) => product.price <= parseInt(filterState.vol)
  );

  return (
    <>
      <BreadcrumbComponent from="Home" to="Product List" />
      {/* FILTER STARTS */}
      <div className="container-fluid ">
        <div className="row searchAndFilterBar d-flex justify-content-between  align-items-center">
          <div className="col-lg-6 col-md-6 col-12   ">
            <div className="searchBoxMainDiv col-lg-9 col-md-9 col-12 d-flex justify-content-center  align-items-center">
              <input
                type="text"
                name="searchProduct"
                className="searchBarProductPage col-lg-11 col-md-11 col-11"
                placeholder="Search Product"
                value={searchProduct}
                onChange={searchProductHandler}
              />
              <div
                // onClick={productSearchHandler}
                style={{ cursor: "pointer" }}
              >
                <span
                  className="input-group-text bg-white searchIcon "
                  id="basic-addon1"
                >
                  <i className="fas fa-search" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* FILTER ENDS */}

      <div className="container-fluid d-flex   MainContainer">
        <div className="row mt-3 p-0 col-lg-12 col-md-12 col-12 ">
          <div className="col-lg-12  col-md-12 col-12 d-flex productListDiv">
            {searchProduct
              ? filterFromSearchBar.map((productDetails) => (
                  // <ProductPageProductList
                  //   id={productDetails.id}
                  //   price={productDetails.price}
                  //   productName={productDetails.productName}
                  //   kgs="1 kg"
                  //   imageName={productDetails.imgName}
                  //   addToCart="#Product_Id"
                  // />
                  <ProductSection product={productDetails} />
                ))
              : filterState.vol
              ? filterFromPrice.map((productDetails) => (
                  // <ProductPageProductList
                  //   id={productDetails.id}
                  //   price={productDetails.price}
                  //   productName={productDetails.productName}
                  //   kgs="1 kg"
                  //   imageName={productDetails.imgName}
                  //   addToCart="#Product_Id"
                  // />
                  <ProductSection product={productDetails} />
                ))
              : ProductList.map((productDetails) => (
                  // <ProductPageProductList
                  //   id={productDetails.id}
                  //   price={productDetails.price}
                  //   productName={productDetails.productName}
                  //   kgs="1 kg"
                  //   imageName={productDetails.imgName}
                  //   addToCart="#Product_Id"
                  // />
                  <ProductSection product={productDetails} />
                ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default ProductListPage;
