import React,{useEffect, useState} from 'react';
import '../../scss/include/advertismentTypeHeaderSection.scss';
import { useHistory } from "react-router-dom";


const AdvertismentTypeHeaderSection = () =>{
    const history = useHistory();
    const [active,setActive]=useState({
        linkFirst:'',
        linkSecond:'',
        linkThird:'',
        linkFourth:''
    });
    const [fontColor,setFontColor] = useState({
        linkFirst:'black',
        linkSecond:'black',
        linkThird:'black',
        linkFourth:'black'
    });
    

    useEffect(()=>{
        if(window.location.pathname === '/advertise-with-us/Store'){
            setActive({linkFirst:'#dc3545'})
            setFontColor({linkFirst:'white'})
        }
        if(window.location.pathname === '/advertise-with-us/Outdoor'){
            setActive({linkSecond:'#dc3545'})
            setFontColor({linkSecond:'white'})

        }
        if(window.location.pathname === '/advertise-with-us/Storeradio'){
            setActive({linkThird:'#dc3545'})
            setFontColor({linkThird:'white'})

        }
        if(window.location.pathname === '/advertise-with-us/SpecialEvents'){
            setActive({linkFourth:'#dc3545'})
            setFontColor({linkFourth:'white'})

        }
        
    },[])

    return(
<>
        <div className="container mb-3">
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col-lg-12 col-md-12 col-12 d-flex justify-content-center align-items-center">
                <span className="titleHS">Media Assets</span>
                </div>
            </div>
        </div>

        <div className="container mb-3 p-0">
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col-lg-9 col-md-9 col-12 advTypeHeaderMainDiv d-flex justify-content-center align-items-center flex-wrap">
                    <a onClick={()=>history.push("/advertise-with-us/Store")} className="advButton d-flex justify-content-center align-items-center" style={{backgroundColor:active.linkFirst, color: fontColor.linkFirst}}><div>In Store</div></a>
                    <a onClick={()=>history.push("/advertise-with-us/Outdoor")} className="advButton d-flex justify-content-center align-items-center" style={{backgroundColor:active.linkSecond , color: fontColor.linkSecond}}><div>Outdoor</div></a>
                    <a onClick={()=>history.push("/advertise-with-us/Storeradio")} className="advButton d-flex justify-content-center align-items-center" style={{backgroundColor:active.linkThird , color: fontColor.linkThird}}><div >In-Store Radio</div></a>
                    <a onClick={()=>history.push("/advertise-with-us/SpecialEvents")} className="advButton d-flex justify-content-center align-items-center" style={{backgroundColor:active.linkFourth , color: fontColor.linkFourth}}><div >Special Events</div></a>
                </div>
            </div>
        </div>



</>
    )
}


export default AdvertismentTypeHeaderSection;