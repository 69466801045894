import React, { useEffect, useState } from "react";
import BreadcrumbComponent from "../CustomComponents/BreadcrumbComponent";
import "../../scss/include/blogDetailsPage.scss";

import api from "../../services/api";
import { consoleError, isJSON } from "../../utils/helper";
import PageSpinner from "../CustomComponents/PageSpinner";
import { useHistory } from "react-router-dom";
import { imageUrl } from "../../utils/helper";

import draftToHtml from "draftjs-to-html";

const BlogDetails = (props) => {
  const history = useHistory();
  const [blog, setBlog] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const {
    match: { params },
  } = props;
  const { slug } = params;

  useEffect(() => {
    async function getBlogDetails() {
      if (slug) {
        try {
          const res = await api.get(`/api/v1/blog/slug/${slug}`);
          if (res && res.data) {
            setBlog(res.data);
          }
          setPageLoading(false);
        } catch (error) {
          consoleError(error);
          setPageLoading(false);
        }
      }
    }
    getBlogDetails();
  }, [slug]);

  return (
    <>
      {pageLoading ? (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <PageSpinner />
        </div>
      ) : blog ? (
        <>
          <BreadcrumbComponent
            from="Home"
            fromHref="/"
            to="Blog"
            toHref="/blog"
            thirdPage={blog.title}
          />
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-12 col-12">
                <h1 className="mt-4">{blog.title}</h1>
                <p className="lead">
                  by
                  <small className="text-muted cat">
                    <i className="fas fa-user text-primary mr-1"></i>
                    {blog.user && blog.user.name ? blog.user.name : blog.user}
                  </small>
                </p>
                <hr />
                <p>Updated on {new Date(blog.updatedAt).toLocaleString()}</p>
                <hr />
                <div className="d-flex justify-content-center">
                  <img
                    className="img-fluid rounded blogBanner"
                    src={imageUrl(blog.banner)}
                    alt="blog-banner"
                  />
                </div>
                <hr />

                <div className="container">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: isJSON(blog.body) ? (
                        draftToHtml(JSON.parse(blog.body))
                      ) : (
                        <div></div>
                      ),
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        history.push("/404")
      )}
    </>
  );
};
export default BlogDetails;
