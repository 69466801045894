import React from "react";
import "../../scss/include/footer.scss";
import logo from "../../assets/img/bhavikaLogo.png";
import useTrans from "../hooks/useTrans";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
// import MultiPromoPopupSystem from '../CustomComponents/PromoImagePopup';
import bhavikavideo from "../../assets/bhavika.mp4";

const Footer = () => {
  const trans = useTrans();
  return (
    <>
      {/* <MultiPromoPopupSystem /> */}
      <footer>
      <div className="container-fluid">
        {/*         <LazyLoad>
                    <div
            className="footer-image"
            // style={{backgroundImage: "url(" + require('../../assets/img/rectangle4.png') + ")"}}
          />
          
        </LazyLoad> */}
        <div className="video-container">
          <video autoPlay muted loop>
            <source src={bhavikavideo} type="video/mp4" />
          </video>
        </div>
      </div>
      <div className="footer-top">
        <div className="container-fluid">
          <div className="row footer-desc">
            <div className="col-lg-3 col-md-6 col-12">
              <ul>
                {trans.whoWeAre}
                <li>
                  <p style={{ textTransform: "none" }}>
                    {trans.whoWeAreContent1}
                  </p>
                </li>
                <li>
                  <p style={{ textTransform: "none" }}>
                    {trans.whoWeAreContent2}
                  </p>
                </li>
                <li>
                  <p style={{ textTransform: "none" }}>
                    {trans.whoWeAreContent3}
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <ul>
                {trans.mostSelling}
                <li>
                  <a href="/category/fruits-and-vegetables">
                    {trans.fruitsAndVegetables}
                  </a>
                </li>
                <li>
                  <a href="/category/grocery/rice-poha-vermicelli">
                    {trans.ricePoha}
                  </a>
                </li>
                <li>
                  <a href="/category/grocery/flour-maida-besan-and-sooji">
                    {trans.flour}
                  </a>
                </li>
                <li>
                  <a href="/category/dairy-and-frozen/milk-products">
                    {trans.milkProducts}
                  </a>
                </li>
                <li>
                  <a href="/category/grocery/masala-and-spices">
                    {trans.masalaAndSpices}
                  </a>
                </li>
                <li>
                  <a href="/category/branded-foods/snacks">{trans.snacks}</a>
                </li>
                <li>
                  <a href="/category/personal-care/hair-care">
                    {trans.hairProducts}
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <ul>
                {trans.aboutBhavika}
                <li>
                  <a href="/about">- {trans.aboutUs}</a>
                </li>
                <li>
                  <a href="/contact">- {trans.contactUs}</a>
                </li>
                <li>
                  <a href="/dollar-reward-system">
                    - {trans.dollarRewardSystem}
                  </a>
                </li>
                <li>
                  <a href="/real-taste-of-india">- {trans.realTasteOfIndia}</a>
                </li>
                {/* <li>
                  <a href="/service">- Services</a>
                </li> */}
                <li>
                  <a href="/shipping">- {trans.deliverySchedule}</a>
                </li>
                <li>
                  <a href="/privacy-policy">- {trans.privacyPolicy}</a>
                </li>
                <li>
                  <a href="/return-refund-policy">
                    - {trans.returnRefundPolicy}
                  </a>
                </li>
                <li>
                  <a href="/advertise-with-us">- {trans.advertiseWithUs}</a>
                </li>
                <li>
                  <a href="/payment-methods">- {trans.paymentMethods}</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <ul>
                {trans.contactInformation}
                <li>
                  <p className="font-weight-bold">Tung Chung Branch</p>
                  <i className="fa fa-map-marker-alt" /> <b>{trans.address}:</b>
                  <br />
                  <p className="contact-content">
                    Shop G03, G/F, T-Bay, 9 Yi Tung Road, Tung Chung, Hong Kong
                  </p>
                </li>
                <li>
                  <i className="fa fa-phone-alt" /> <b>{trans.callUsOn}:</b>
                  <p className="contact-content">
                    <FontAwesomeIcon icon={faCircle} style={{ width: "5px" }} />{" "}
                    &nbsp; <a href="tel:+852 2415 4777">+852 2415 4777</a>
                    <br />
                    <FontAwesomeIcon
                      icon={faCircle}
                      style={{ width: "5px" }}
                    />{" "}
                    &nbsp;
                    <a href="tel:+852 2415 5177">+852 2415 5177</a>
                    <br />
                    <FontAwesomeIcon
                      icon={faCircle}
                      style={{ width: "5px" }}
                    />{" "}
                    &nbsp;
                    <a href="tel:+852 2447 2000">+852 2447 2000</a>
                    <br />
                    <FontAwesomeIcon
                      icon={faCircle}
                      style={{ width: "5px" }}
                    />{" "}
                    &nbsp;
                    <a href="tel:+852 2342 2000">+852 2342 2000</a>
                    <br />
                    {/* &nbsp; | &nbsp;<a href="tel:+852 2415 5177">2415 5177</a>
                    &nbsp; | &nbsp; <a href="tel:+852 2447 2000">2447 2000</a>
                    &nbsp; | &nbsp; <a href="tel:+852 2342 2000">2342 2000</a> */}
                    {/* WhatsApp:- &nbsp;{" "}
                    <a href="https://wa.me/+85297777510">+852 97777510</a>
                    <br />
                    Voice Call:- &nbsp;{" "}
                    <a href="tel:+852 2415 4777">+852 2415 4777</a> */}
                  </p>
                </li>
                <li>
                  <i className="fab fa-whatsapp" style={{ fontSize: "20px" }} />{" "}
                  <b>WhatsApp (text only):</b>
                  <p className="contact-content">
                    <FontAwesomeIcon icon={faCircle} style={{ width: "5px" }} />{" "}
                    &nbsp;{" "}
                    <a href="https://wa.me/+85297777510">+852 97777510</a>
                    <br />
                    <FontAwesomeIcon
                      icon={faCircle}
                      style={{ width: "5px" }}
                    />{" "}
                    &nbsp;
                    <a href="tel:+852 9183 1538">+852 9183 1538</a>
                    <br />
                  </p>
                </li>
                <li>
                  <i
                    className="fa fa-fax"
                    aria-hidden="true"
                    style={{ fontSize: "20px" }}
                  />{" "}
                  <b>{trans.fax}:</b>
                  <p className="contact-content">
                    <a href="fax:+852 2415 4977">+852 2415 4977</a>
                  </p>
                </li>
                <li>
                  <i className="fa fa-envelope" /> <b>{trans.email}:</b>
                  <p className="contact-content no-text-transform">
                    <a href="mailto:info@bhavika.online">info@bhavika.online</a>
                  </p>
                </li>
                <li>
                  <i className="far fa-clock" /> <b>{trans.workingDays}:</b>
                  <p className="contact-content mb-2">Mon-Sun / 10:00AM - 09:00PM (Tseung Kwan O Branch)</p>
                  <p className="contact-content">Mon-Sun / 09:00AM - 11:00PM (Tung Chung Branch)</p>
                </li>
                <li>
                  <div className="d-flex">
                    <i
                      className="fa fa-question-circle"
                      aria-hidden="true"
                      style={{ fontSize: "20px" }}
                    />
                    <b className="ml-1">For Any Technical Support:</b>
                  </div>
                  <p className="contact-content">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://wa.me/+85297395784"
                    >
                      <i className="fab fa-whatsapp"></i>
                      +852 9739 5784
                    </a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container-fluid ft_bottom">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 footer-logo mx-auto text-left">
              <div className="row">
                <div className="col-md-4 col-sm-12 footer-bottom-logo">
                  <a href="/">
                    <img src={logo} alt="logo" />
                  </a>
                </div>
                <div className="col-md-8">
                  <div className="social-logos">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/bhavikarajstore"
                    >
                      <i className="fab fa-facebook"></i>
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://wa.me/+85297777510"
                    >
                      <i className="fab fa-whatsapp"></i>
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://twitter.com/BhavikaGroceri1"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.pinterest.com/bhavikagroceries/"
                    >
                      <i className="fab fa-pinterest"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 ">
              <div className="copyright">
                <p>©Copyright 2020. All Rights Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*        <i className="fa fa-phone" />*/}
      {/*        Call Us On:*/}
      {/*      </span>*/}
      {/*      <br />*/}
      {/*      +852 XXXX XXXX*/}
      {/*      <br />*/}
      {/*      <br />*/}
      {/*      <span>*/}
      {/*        <i className="fa fa-whatsapp" />*/}
      {/*        Whatsapp Us On:*/}
      {/*      </span>*/}
      {/*      <br />*/}
      {/*      +852 XXXX XXXX*/}
      {/*      <br />*/}
      {/*      <br />*/}
      {/*      <span>*/}
      {/*        <i className="fa fa-mail-forward" />*/}
      {/*        Email:*/}
      {/*      </span>*/}
      {/*      <br />*/}
      {/*      info@bhvika.com*/}
      {/*      <br />*/}
      {/*      <br />*/}
      {/*      <span>*/}
      {/*        <i className="fa fa-clock-o" />*/}
      {/*        Working Days/Hours:*/}
      {/*      </span>*/}
      {/*      <br />*/}
      {/*      Mon-Sun / 10:30 AM - 10:30 PM*/}
      {/*      <br />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/*<div className="footerMainDiv">*/}
      {/*  <div className="footerRightDiv">*/}
      {/*    <a href="/">*/}
      {/*      <img className="BrandLogo" alt="bhavika_logo" src={logo} />*/}
      {/*    </a>*/}

      {/*  </div>*/}
      {/*  <div className="footerLeftDiv">*/}
      {/*    <span>@Copyright 2020.All Rights Reserved.</span>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </footer>
    </>
  );
};
export default Footer;
