import { useContext } from "react";
import { BhavikaContext } from "../Context/Bhavika";

const useTransPara = (type) => {
  const { lang } = useContext(BhavikaContext);
  switch (type) {
    case "description":
      return function (obj) {
        return lang === "hk" && obj.description_hk
          ? obj.description_hk
          : obj.description;
      };
    default:
      return function (obj) {
        return lang === "hk" && obj.name_hk ? obj.name_hk : obj.name;
      };
  }
};

export default useTransPara;
