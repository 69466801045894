import React, { useContext, useEffect, useState } from "react";
import BreadcrumbComponent from "../CustomComponents/BreadcrumbComponent";
import "../../scss/include/cart.scss";
import { CartContext } from "../Context/Cart";
import { imageUrl } from "../../utils/helper";
import api from "../../services/api";
import { ServiceTax, VAT } from "../../utils/constants";
import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { consoleError } from "../../utils/helper";
import Modal from "react-bootstrap/Modal";
import { BhavikaContext } from "../Context/Bhavika";
import localCart from "../../services/cart";
import useTrans from "../hooks/useTrans";
import popupimg from "../../assets/img/bhavika_popup.jpg";

// import CartHeadingComponent from "../CustomComponents/CartHeadingComponent";
// import CartProductComponent from "../CustomComponents/CartProductComponent";
// import CartSubTotalAndTaxCalculationComponents from "../CustomComponents/CartSubTotalAndTaxCalculationComponents";
// import CartPageButtonsComponents from "../CustomComponents/CartPageButtonsComponents";

const Cart = () => {
  const history = useHistory();
  const trans = useTrans();
  const { cart, setCart } = useContext(CartContext);
  const { isAuthenticated, setLoginShow } = useContext(BhavikaContext);

  const [showEmptyCartModal, setShowEmptyCartModal] = useState(false);
  const [blindFoundationModal, setBlindFoundationModal] = useState(false);
  const [isAlcohol, setIsAlcohol] = useState(false);
  const [ageVerified, setAgeVerified] = useState(false);
  const [alcoholModalShow, setAlcoholModalShow] = useState(false);

  useEffect(() => {
    async function getCartItems() {
      try {
        if (isAuthenticated) {
          const res = await api.get("api/v1/user/cart");
          if (res && res.ok) {
            setCart(res.data);
          }
        } else {
          setCart(localCart.getCart());
        }
      } catch (error) {
        consoleError(error);
      }
    }
    getCartItems();
  }, [isAuthenticated, setCart]);
  useEffect(() => {
    setIsAlcohol(false);
    cart.map((cart_item) => {
      if (cart_item.product.category == "6125c2ff203200629c339c73") {
        setIsAlcohol(true);
        return;
      }
    });
  }, [cart]);
  const updateCart = async (product, action) => {
    try {
      if (isAuthenticated) {
        let res = await api.post("/api/v1/user/cart/update", {
          product,
          action,
        });
        if (res && res.data) {
          setCart(res.data);
        }
      } else {
        if (action === "plus") {
          localCart.addOneProduct(product);
        }
        if (action === "minus") {
          localCart.removeOneProduct(product);
        }
        setCart(localCart.getCart());
      }
    } catch (error) {
      consoleError(error);
    }
  };
  const removeFromCart = async (product) => {
    try {
      if (isAuthenticated) {
        let res = await api.post("/api/v1/user/cart/remove", {
          product,
        });
        if (res && res.ok) {
          setCart(res.data);
        }
      } else {
        localCart.removeProduct(product);
        setCart(localCart.getCart());
      }
    } catch (error) {
      consoleError(error);
    }
  };
  const resetCart = async () => {
    // const confirm = window.confirm("Are you sure you want to empty the cart?");
    //  confirm(true);
    // if (confirm) {
    try {
      if (isAuthenticated) {
        let res = await api.get("/api/v1/user/cart/reset");
        if (res && res.ok) {
          setCart(res.cart);
          setShowEmptyCartModal(false);
        }
      } else {
        localCart.emptyCart();
        setCart(localCart.getCart());
        setShowEmptyCartModal(false);
      }
    } catch (error) {
      consoleError(error);
    }
    // }
  };
  const SubTotal = cart
    .reduce((acc, item) => (acc += item.quantity * item.product?.price), 0)
    .toFixed(2);

  const Total = (
    ((100 + VAT) / parseFloat(100)) *
    (((100 + ServiceTax) / parseFloat(100)) * SubTotal)
  ).toFixed(2);

  const closeEmptyCartModal = () => {
    setShowEmptyCartModal(false);
  };

  const closeBlindFoundationModal = () => {
    setBlindFoundationModal(false);
    history.push("/checkout");
  };

  const handleAge = (age) => {
    if (age) {
      if (isAuthenticated) {
        setBlindFoundationModal(true);
      } else {
        setLoginShow(true);
      }
    } else {
      setAlcoholModalShow(false);
      alert("sorry you are under age");
    }
  };

  return (
    <>
      <div className={"cart-section"}>
        <BreadcrumbComponent
          from="Home"
          fromHref="/"
          to="Cart"
          toHref="/cart"
        />
        {/* CART HEADING STARTS */}
        <div className="container">
          <div className="table-responsive">
            <table className="table table-striped cart-table">
              <thead>
                <tr>
                  <th className={"text-center"}>Items</th>
                  <th className={"text-center"}>Quantity</th>
                  <th className={"text-center"}>Price</th>
                  <th style={{ width: "10%" }}></th>
                </tr>
              </thead>
              <tbody>
                {cart && Array.isArray(cart) && cart.length > 0 ? (
                  cart.map((item, index) => (
                    <tr key={index}>
                      <td style={{ width: "30%" }}>
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-12 align-items-center">
                            <img
                              src={imageUrl(item.product?.banner)}
                              className="img-fluid"
                              alt="cart product"
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-12 align-items-center ">
                            {item.product?.name}
                            {/* <br />
                            {item.product.weight
                              ? item.product.weight.value +
                                item.product.weight.unit
                              : null} */}
                          </div>
                        </div>
                      </td>
                      <td className={"text-center"}>
                        <span
                          onClick={() => {
                            updateCart(item.product._id, "minus");
                          }}
                        >
                          <i
                            className="fa fa-minus"
                            style={{ cursor: "pointer" }}
                          />
                        </span>
                        &nbsp;&nbsp;
                        <span className={"text"}>{item.quantity}</span>
                        &nbsp;&nbsp;
                        <span
                          onClick={() => {
                            updateCart(item.product._id, "plus");
                          }}
                        >
                          <i
                            className="fa fa-plus"
                            style={{ cursor: "pointer" }}
                          />
                        </span>
                      </td>
                      <td className={"text-center"}>
                        <span className="text">
                          ${Number(item.product?.price).toFixed(2)}
                        </span>
                      </td>
                      <td className={"text-left"} style={{ width: "10%" }}>
                        <span
                          onClick={() => {
                            removeFromCart(item.product._id);
                          }}
                        >
                          <i
                            className="fa fa-times cancel-product"
                            style={{ cursor: "pointer" }}
                          />
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>
                      <p className="text-center">
                        No items in cart. Please head to{" "}
                        <Link to="/">home</Link> and continue shopping
                      </p>
                    </td>
                  </tr>
                )}
                <tr className={"sub-heading"}>
                  <td></td>
                  <td className="d-flex justify-content-end align-items-center">
                    SubTotal
                  </td>
                  <td>${SubTotal}</td>
                  <td></td>
                </tr>
                <tr className={"sub-heading"}>
                  <td></td>
                  <td className="d-flex justify-content-end align-items-center">
                    Service Tax
                  </td>
                  <td>{ServiceTax}%</td>
                  <td></td>
                </tr>
                <tr className={"sub-heading"}>
                  <td></td>
                  <td className="d-flex justify-content-end align-items-center">
                    VAT
                  </td>
                  <td>{VAT}%</td>
                  <td></td>
                </tr>
                <tr className={"sub-heading"}>
                  <td></td>
                  {/* <td className={"text-center"}>Total</td> */}
                  <td className="d-flex justify-content-end align-items-center">
                    Total
                  </td>
                  <td>${Total}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12 discount-section">
              <button
                className={"btn btn-sm btn-danger w-25"}
                disabled={cart && Array.isArray(cart) && cart.length < 1}
                // onClick={resetCart}
                onClick={() => setShowEmptyCartModal(true)}
              >
                {trans.emptyCart}
              </button>
            </div>
            <div className="col-md-6 col-sm-6 col-12 checkout-section">
              <Button
                className={"btn btn-sm rounded-pill"}
                type={"submit"}
                onClick={() => {
                  if (isAlcohol) {
                    setAlcoholModalShow(true);
                  } else {
                    if (isAuthenticated) {
                      setBlindFoundationModal(true);
                    } else {
                      setLoginShow(true);
                    }
                  }
                }}
                disabled={
                  !cart || (cart && Array.isArray(cart) && cart.length < 1)
                }
              >
                {trans.checkout}
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* Emptycart MODAL */}
      <Modal
        size="md"
        show={showEmptyCartModal}
        onHide={closeEmptyCartModal}
        aria-labelledby="example-modal-sizes-title-lg"
        className="modelMainDiv"
        centered
      >
        <Modal.Header>
          <h5>Empty Cart?</h5>
          <button
            class="btn btn-outline-secondary btn-circle"
            onClick={closeEmptyCartModal}
          >
            <i class="fa fa-times"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to empty your cart?
          <br />
          <div className="container d-flex justify-content-end align-items-center mt-2">
            <Button
              variant="outline-info mr-2 rounded-pill"
              onClick={closeEmptyCartModal}
            >
              cancel
            </Button>
            <Button variant="outline-success rounded-pill" onClick={resetCart}>
              {"Yes"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* Emptycart MODAL */}
      <Modal
        size="md"
        show={alcoholModalShow}
        aria-labelledby="example-modal-sizes-title-lg"
        className="modelMainDiv"
        centered
      >
        <Modal.Header>
          <h5>Verify your age</h5>
        </Modal.Header>
        <Modal.Body>
          Please verify your age is 18 or above
          <br />
          <div className="container d-flex justify-content-end align-items-center mt-2">
            <Button
              variant="outline-success rounded-pill"
              onClick={() => {
                handleAge(1);
              }}
            >
              Yes
            </Button>
            <Button
              variant="outline-info ml-2 rounded-pill"
              onClick={() => {
                handleAge(0);
              }}
            >
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* blind foundation MODAL */}
      <Modal
        size="md"
        show={blindFoundationModal}
        onHide={closeBlindFoundationModal}
        aria-labelledby="example-modal-sizes-title-lg"
        className="modelMainDiv"
        centered
      >
        <Modal.Body>
          <a href="https://www.helptheblindfoundation.org" target="blanky">
            <img
              src={popupimg}
              alt="help blind foundation"
              style={{ maxWidth: "100%" }}
            />
          </a>
          <div className="container d-flex justify-content-end align-items-center mt-2">
            <Button
              variant="outline-info mr-2 rounded-pill"
              onClick={closeBlindFoundationModal}
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Cart;
