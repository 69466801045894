import { useContext } from "react";
import { BhavikaContext } from "../Context/Bhavika";
import { languageList } from "../../translations";

const useTrans = () => {
  const { lang } = useContext(BhavikaContext);
  return languageList[lang];
};

export default useTrans;
