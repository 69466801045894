import React, { useContext, useEffect, useState } from "react";
import BreadcrumbComponent from "../CustomComponents/BreadcrumbComponent";
import CategoryPageProductHeading from "../CustomComponents/CategoryPageProductHeading";
import ProductSection from "../CustomComponents/ProductSection";
import HeadingProductsForCategoryPage from "../CustomComponents/HeadingProductsForCategoryPage";
// import ProductFilter from "../CustomComponents/ProductFilter";
import "../../scss/include/category.scss";
import Image from "../../assets/img/testImg.PNG";
import api from "../../services/api";
import { CategoryContext } from "../Context/Category";
import PageSpinner from "../CustomComponents/PageSpinner";
import Modal from "react-bootstrap/Modal";
import minorImg from '../../assets/img/liquor_law.jpg';
import { consoleError } from "../../utils/helper";
import PNAImage from "../../assets/Others/Product not found vector.svg";
import LazyLoad from "react-lazyload";
import useTransPara from "../hooks/useTransPara";

const Category = (props) => {
  const { category: categories } = useContext(CategoryContext);

  const transName = useTransPara("name");
  const [pageLoading, setPageLoading] = useState(true);
  const [category, setCategory] = useState(null);
  const [featured, setFeatured] = useState([]);
  const [donthaveProduct, setDontHaveProduct] = useState(true);
  const [limit, setLimit] = useState(10);
  const [minorModal, setMinormodal] = useState(false);
  const {
    match: { params },
  } = props;
  const { slug } = params;

  useEffect(() => {
    async function getCategoryDetails() {
      if (slug) {
        if (slug === "alcohol") {
          setMinormodal(true)
          setLimit(100);
        }
        try {
          const res = await api.get(`/api/v1/category/slug/${slug}`);
          if (res && res.data) {
            setCategory(res.data);
            // console.log(`Product is : ${res.data.products}`)
            if (res.data.subcategory.length >= 1) {
              setDontHaveProduct(false);
            }
          }
          setPageLoading(false);
        } catch (error) {
          consoleError(error);

          setPageLoading(false);
        }
      }
    }
    getCategoryDetails();
  }, [slug]);

  const closeMinorModal = () => {
    setMinormodal(false);
  };

  useEffect(() => {
    async function getFeatured() {
      if (category && category._id) {
        try {
          const res = await api.get(
            `/api/v1/category/featured/${category._id}`
          );
          if (res && res.data) {
            setFeatured(res.data);
          }
          setPageLoading(false);
        } catch (error) {
          consoleError(error);
          setPageLoading(false);
        }
      }
    }
    getFeatured();
  }, [category]);

  return (
    <>
      <div className="container-fluid">
        <div className="row d-flex justify-content-center align-items-center ">
          {categories.map((item, index) => (
            <React.Fragment key={index}>
              <CategoryPageProductHeading
                name={transName(item)}
                redirectLink={`/category/${item.slug}`}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
      {pageLoading ? (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <PageSpinner />
        </div>
      ) : // Check if there is product or not in this cartegory
      donthaveProduct ? (
        <div className="d-flex flex-column justify-content-center align-items-center mt-3">
          <div className="text-center text-muted mb-2">
            <h4>Sorry products not available in this category</h4>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <LazyLoad>
              <img
                className="img-fluid"
                src={PNAImage}
                alt="Products Not Found"
                style={{ height: "300px" }}
              />
            </LazyLoad>
          </div>
        </div>
      ) : // Check if there is product or not in this cartegory Ends

      category ? (
        <>
          <BreadcrumbComponent
            from="Home"
            fromHref="/"
            to={transName(category)}
            toHref={`/category/${category.slug}`}
          />

          {/* FILTER STARTS */}
          {/* <div className="container-fluid ">
            <div className="row d-flex justify-content-center  align-items-center">
              <ProductFilter />
            </div>
          </div> */}
          {/* FILTER ENDS */}

          <div className="container-fluid">
            <div className="row  pl-2 pr-2 mt-4 d-flex justify-content-center align-items-center">
              {featured.slice(0, 3).map((featuredItem) => (
                <HeadingProductsForCategoryPage
                  heading="Featured"
                  productName={transName(featuredItem)}
                  redirectLink={`/category/${slug}/${featuredItem.subcategory.slug}`}
                  banner={featuredItem.banner}
                  hrefName="View Products"
                />
              ))}
            </div>
          </div>

          {category.subcategory.map((subcategory, index) => (
            <React.Fragment key={index}>
              {subcategory.products.filter((product) => product.enabled)
                .length > 0 ? (
                <div className="container-fluid">
                  <div className="row mb-5 align-items-start p-3">
                    {/* PRODUCT CATEGORY AND TOTAL PRODUCT DETAILS */}
                    <div className="col-lg-2 col-md-3 col-sm-6 col-12 border border-grey  d-flex  align-items-center justify-content-between flex-column p-3 productNameAndViewAllCard">
                      <div className="w-100   font-weight-bold ">
                        {transName(subcategory)}
                      </div>

                      <div className="w-100   ">
                        <a
                          href={`/category/${category.slug}/${subcategory.slug}`}
                        >
                          View All {subcategory.totalProducts} Products
                        </a>
                      </div>
                    </div>
                    {/* PRODUCT CATEGORY AND TOTAL PRODUCT DETAILS ENDS*/}
                    {subcategory.products
                      .slice(0, limit)
                      .filter((product) => product.enabled)
                      .map((product, index) => (
                        <React.Fragment key={index}>
                          <ProductSection product={product} />
                        </React.Fragment>
                      ))}
                    {/* View Assortment Starts Here */}
                    <div className="col-lg-2 col-md-3 col-sm-6 col-12 border border-grey  d-flex  align-items-center justify-content-between flex-column p-3 productNameAndViewAllCard">
                      <div className="w-100 font-weight-bold">
                        Choose More <br />
                        {transName(subcategory)}
                      </div>

                      <div className="w-100 ">
                        <a
                          href={`/category/${category.slug}/${subcategory.slug}`}
                        >
                          View Assortment
                        </a>
                      </div>
                    </div>
                    {/* View Assortment Ends Here */}
                  </div>
                </div>
              ) : null}
            </React.Fragment>
          ))}

          {/* PRODUCT IMAGE DIV STARTS */}
          {/* <div className="container-fluid">
            <div className="row  p-3">
              <div className="col-lg-6 col-md-6  col-12 d-flex justify-content-center align-items-center ">
                <LazyLoad>
                  <img src={Image} className="img-fluid" alt="category" />
                </LazyLoad>
              </div>
              <div className="col-lg-6 col-md-6  col-12  d-flex justify-content-center align-items-center">
                <LazyLoad>
                  <img src={Image} className="img-fluid" alt="category" />
                </LazyLoad>
              </div>
            </div>
          </div> */}
          {/* PRODUCT IMAGE DIV ENDS */}
        </>
      ) : (
        <div className="container-fluid">
          <div className="row d-flex justify-content-center align-items-center ">
            <div className="text-center">
              <h3>{`Category "${slug}" Not found.`}</h3>
              <h4>Please check from the list above.</h4>
            </div>
          </div>
        </div>
      )}
        {/* Minor MODAL */}
        <Modal
        size="md"
        show={minorModal}
        onHide={closeMinorModal}
        aria-labelledby="example-modal-sizes-title-lg"
        className="modelMainDiv"
        centered
      >
        <Modal.Body>
        <button
            class="btn btn-outline-secondary btn-circle"
            onClick={closeMinorModal}
            style={{ position: "absolute", top: "-10px", right: "-10px", backgroundColor: "#fff" }}
          >
            <i class="fa fa-times"></i>
          </button>
        <a href="#">
          <img src={ minorImg } alt="" style={{maxWidth: '100%'}} />
        </a>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Category;
