const localCart = (() => {
  let cart = [];

  function Product(product, quantity) {
    this.product = product;
    this.quantity = quantity;
  }

  // Save cart
  function saveCart() {
    localStorage.setItem("localCart", JSON.stringify(cart));
  }

  // Load cart
  function loadCart() {
    cart = JSON.parse(localStorage.getItem("localCart"));
  }
  if (localStorage.getItem("localCart") != null) {
    loadCart();
  }

  return {
    addProduct: (product, quantity = 1) => {
      const index = cart.findIndex((item) => item.product._id === product._id);
      if (index !== -1) {
        cart[index].quantity += quantity;
        saveCart();
      } else {
        const item = new Product(product, quantity);
        cart.push(item);
        saveCart();
      }
    },
    addOneProduct: (_id, quantity = 1) => {
      const index = cart.findIndex((item) => item.product._id === _id);
      if (index !== -1) {
        cart[index].quantity += quantity;
        saveCart();
      }
    },

    removeOneProduct: (_id, quantity = 1) => {
      const index = cart.findIndex((item) => item.product._id === _id);
      if (index !== -1) {
        cart[index].quantity -= quantity;
        if (cart[index].quantity === 0) {
          cart.splice(index, 1);
        }
        saveCart();
      }
    },
    removeProduct: (_id) => {
      const index = cart.findIndex((item) => item.product._id === _id);
      if (index !== -1) {
        cart.splice(index, 1);
        saveCart();
      }
    },
    emptyCart: () => {
      cart = [];
      saveCart();
    },
    getSubTotal: () => {
      return cart.reduce((acc, item) => {
        acc += +item.product.price * +item.quantity;
        return acc;
      }, 0);
    },
    getCart: () => {
      return [...cart];
    },
  };
})();

export default localCart;
