import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { consoleError, toTitleCase } from "../../utils/helper";
import BreadcrumbComponent from "./BreadcrumbComponent";
import PageSpinner from "./PageSpinner";
import ProductListWithSearch from "./ProductListWithSearch";

export default function ProductListPage({ page, match }) {
  const [pageLoading, setPageLoading] = useState(true);
  const [productList, setProductList] = useState([]);
  const [details, setDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const {
    params: { slug },
  } = match;
  useEffect(() => {
    async function getDataBySlug() {
      try {
        const res = await api.get(`/api/v1/${page}/slug/${slug}`);
        if (res && res.ok) {
          if (res.data) {
            const { products, ...details } = res.data;
            setDetails(details);
            setProductList(products);
          }
        }
        setPageLoading(false);
      } catch (error) {
        consoleError(error);
        setErrorMessage(
          error && error.message
            ? error.message
            : `${toTitleCase(page)} ${slug} not found.`
        );
        setPageLoading(false);
      }
    }
    if (slug) getDataBySlug();
  }, [page, slug]);
  return (
    <div>
      {pageLoading ? (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <PageSpinner />
        </div>
      ) : details ? (
        <>
          <BreadcrumbComponent
            from="Home"
            fromHref="/"
            to={toTitleCase(page)}
            thirdPage={details.name}
          />
          <ProductListWithSearch productList={productList} />
        </>
      ) : (
        <div className="container-fluid">
          <div className="row d-flex justify-content-center align-items-center mt-5">
            <div className="text-center">
              {errorMessage ? (
                <h3>{errorMessage}</h3>
              ) : (
                <h3>{`${toTitleCase(page)} "${slug}" not found.`}</h3>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
