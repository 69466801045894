import React from "react";
import "../../scss/include/shopByHeading.scss";

const ShopByHeading = (props) => {
  return (
    <div className="container-fluid mainDiv">
      <span id={props.id ? props.id : ''}>{props.title}</span>
    </div>
  );
};
export default ShopByHeading;
