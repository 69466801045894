import React, { useContext, useState } from "react";
import "../../scss/include/productSection.scss";
import Modal from "react-bootstrap/Modal";
import { productUrl } from "../../utils/helper";
import api from "../../services/api";
import { CartContext } from "../Context/Cart";
import { Alert, Button, Spinner } from "react-bootstrap";
import { BhavikaContext } from "../Context/Bhavika";
import { useHistory } from "react-router-dom";
import { consoleError, consoleLog } from "../../utils/helper";
import LazyLoad from "react-lazyload";
import useTransPara from "../hooks/useTransPara";
import localCart from "../../services/cart";
import useTrans from "../hooks/useTrans";

const ProductSection = ({
  product,
  discount_type,
  discount,
  promotion_on,
  removeData,
  type = "normal",
}) => {
  const history = useHistory();
  const trans = useTrans();
  const transName = useTransPara("name");
  const transDescription = useTransPara("description");
  const [lgShow, setLgShow] = useState(false);
  const { setCart } = useContext(CartContext);
  const { setLoginShow } = useContext(BhavikaContext);
  const [submitting, setSubmitting] = useState(false);
  const [wishlistSubmiting, setWishlistSubmitting] = useState(false);
  const [productQuantity, setProductQuantity] = useState(1);
  const [showLoginMsg, setShowLoginMsg] = useState(false);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [addedQuantity, setAddedQuantity] = useState(0);
  const productModal = () => {
    setShowSuccessModal(false);
    setLgShow(true);
  };

  const openProductSuccessModal = (quantity) => {
    setAddedQuantity(quantity);
    setShowSuccessModal(true);
  };

  const closeProductSuccessModal = () => {
    setAddedQuantity(0);
    setShowSuccessModal(false);
  };

  const addToCartButton = async () => {
    // alert(
    //   `Product Image Name: ${product.banner}, Product Name is: ${product.name}, Price is: ${product.price}, Kgs is: ${product.unit}, Quantity is: ${productQuantity}`
    // );
    if (localStorage.userData) {
      setSubmitting(true);
      try {
        let res = await api.post("/api/v1/user/cart/add", {
          product: product._id,
          quantity: productQuantity,
        });
        if (res && res.ok) {
          setCart(res.data);
          setSubmitting(false);
          setLgShow(false);
          openProductSuccessModal(productQuantity);
          setProductQuantity(1);
        }
      } catch (error) {
        consoleError(error);
        setSubmitting(false);
      }
    } else {
      // setShowLoginMsg(true);
      localCart.addProduct(product, productQuantity);
      setCart(localCart.getCart());
      setLgShow(false);
      openProductSuccessModal(productQuantity);
      setProductQuantity(1);
    }
  };

  const addToWishlist = async () => {
    if (localStorage.userData) {
      setWishlistSubmitting(true);
      try {
        let res = await api.post("/api/v1/user/wishlist", {
          action: "plus",
          product: product._id,
        });
        if (res && res.ok) {
          setWishlistSubmitting(false);
          setLgShow(false);
        }
      } catch (error) {
        consoleError(error);
        setWishlistSubmitting(false);
      }
    } else {
      setShowLoginMsg(true);
    }
  };

  const headToLogin = () => {
    setShowLoginMsg("");
    setLgShow(false);
    setLoginShow(true);
  };

  const removeFromWishList = () => {
    // alert('removed From wishList')
    api
      .post("/api/v1/user/wishlist", {
        product: product._id,
        action: "remove",
      })
      .then((res) => {
        consoleLog(res);
        window.location.reload();
      })
      .catch((err) => {
        consoleError(err);
      });
  };

  const productQuantityDecreased = () => {
    if (productQuantity <= 0) {
      setProductQuantity(0);
    } else {
      setProductQuantity(productQuantity - 1);
    }
  };
  const productQuantityIncreased = () => {
    setProductQuantity(productQuantity + 1);
  };

  return (
    <>
      {type === "bestbuyproduct" ? (
        <div
          className="shop-best-card col"
          onClick={productModal}
          // onClick={() => <ProductModal />}
          style={{ cursor: "pointer" }}
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a>
            <LazyLoad height={200}>
              <div className="card">
                <img
                  className="card-img-top"
                  loading="lazy"
                  src={productUrl(product.banner)}
                  alt={product.name}
                />
                <div className="card-body">
                  <h6 className="card-title">
                    <div className="w-100   d-flex justify-content-center font-weight-bold">
                      ${product.price}
                    </div>
                    <div className="w-100 " style={{ height: "65px" }}>
                      {transName(product)}
                    </div>
                  </h6>
                </div>
              </div>
            </LazyLoad>
          </a>
        </div>
      ) : type === "topPicksProduct" ? (
        <>
          <div
            className="shop-best-card-random col"
            onClick={productModal}
            // onClick={() => <ProductModal />}
            style={{ cursor: "pointer" }}
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a>
              <div className="card">
                <LazyLoad height={200}>
                  <img
                    className="card-img-top"
                    loading="lazy"
                    src={productUrl(product.banner)}
                    alt={product.name}
                  />
                </LazyLoad>
                <div className="card-body">
                  <h6 className="card-title">
                    <div className="w-100   d-flex justify-content-center font-weight-bold">
                      ${product.price}
                    </div>
                    <div className="w-100 " style={{ height: "65px" }}>
                      {transName(product)}
                    </div>
                  </h6>
                </div>
              </div>
            </a>
          </div>
        </>
      ) : (
        <div
          className="col-lg-2 col-md-3 col-sm-6 border border-grey product"
          onClick={productModal}
          style={{ cursor: "pointer" }}
        >
          <div
            className="w-100  d-flex justify-content-center align-items-center imgTopCategoryPageSectionFirst"
            style={{ overflow: "hidden" }}
          >
            <LazyLoad height={200}>
              <img
                src={productUrl(product.banner)}
                className="img-fluid imgSize"
                alt={product.name}
                loading="lazy"
              />
            </LazyLoad>
          </div>
          <div className="w-100   d-flex justify-content-end font-weight-bold">
            {discount_type === "percentage" ? (
              <>
                <span
                  style={{
                    textDecoration: "line-through",
                    marginRight: "10px",
                  }}
                >
                  ${product.price}
                </span>
                <span>{"$" + ((100 - discount) / 100) * product.price}</span>
              </>
            ) : discount_type === "price" ? (
              <>
                <span
                  style={{
                    textDecoration: "line-through",
                    marginRight: "10px",
                  }}
                >
                  ${product.price}
                </span>
                <span>${product.price - discount}</span>
              </>
            ) : (
              "$" + product.price
            )}
          </div>
          <div className="w-100 " style={{ height: "65px" }}>
            {transName(product)}
            <p>{product.unit}</p>
          </div>
          <div className="w-100 p-2  d-flex justify-content-end">
            <div>
              <i className="fa fa-cart-plus" style={{ color: "#21920f" }} />
            </div>
          </div>
          {/* <div className=" d-flex justify-content-center align-items-center">
        {removeData
        ?
        <span style={{color:'green'}} onClick={()=>alert('removing')}>Remove</span>
        : 
        ('')
       }
        </div>
        */}
        </div>
      )}

      {/* MODAL STARTS */}
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => {
          setLgShow(false);
          setShowLoginMsg(false);
          setSubmitting(false);
          setProductQuantity(1);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
        className="modelMainDiv"
        centered
      >
        <Modal.Body>
          <div className="d-flex w-100  justify-content-end">
            <div
              className="w-10 closeButtonProductModal"
              onClick={() => {
                setLgShow(false);
                setShowLoginMsg(false);
                setSubmitting(false);
                setProductQuantity(1);
              }}
            >
              {/* <Modal.Header closeButton></Modal.Header> */}
              <div
                style={{
                  color: "black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                X
              </div>
            </div>
          </div>

          <div className="productModalInnerDiv  d-flex ">
            <div className="productModalLeftDiv    d-flex justify-content-center align-items-center">
              <div className="col-lg-9 col-md-9 col-12 border border-grey  imgTopCategoryPageSectionFirstModal">
                <div
                  className="w-100 h-75 d-flex justify-content-center align-items-center "
                  style={{ overflow: "hidden", cursor: "pointer" }}
                  onClick={() => history.push(`/products/${product.slug}`)}
                >
                  <img
                    src={productUrl(product.banner)}
                    className="img-fluid imgSize"
                    alt="cat-product"
                  />
                </div>
                <div className="w-100   d-flex justify-content-end font-weight-bold ">
                  ${product.price}
                </div>
                <div className="w-100  ">
                  {transName(product)}
                  <p>{product.unit}</p>
                </div>
                {/* <div className="w-100 p-2  d-flex justify-content-end">
                <a href="/#">
                  <i className="fa fa-cart-plus" style={{ color: "#21920f" }} />
                </a>
              </div> */}
              </div>
            </div>
            <div className="productodalRightDiv  ml-2 d-flex flex-column justify-content-center align-items-center">
              <div className="  descriptionDiv ">
                <span className="font-weight-bold">Product Description</span>
                <br />
                {transDescription(product)}
              </div>
              {product.outOfStock ? (
                <div className="text text-danger">
                  Sorry, the product is currently out of stock.
                </div>
              ) : (
                <>
                  <div className=" h-100 w-75  d-flex justify-content-center  align-items-center ">
                    <span
                      className="d-flex justify-content-center align-items-center"
                      onClick={productQuantityDecreased}
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "15px",
                        backgroundColor: "grey",
                        color: "white",
                      }}
                    >
                      <i
                        className="fa fa-minus minusIcon"
                        style={{ cursor: "pointer", fontSize: "20px" }}
                      />
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="productQuantity">{productQuantity}</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span
                      onClick={productQuantityIncreased}
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "15px",
                        backgroundColor: "grey",
                        color: "white",
                      }}
                    >
                      <i
                        className="fa fa-plus plusIcon"
                        style={{ cursor: "pointer", fontSize: "20px" }}
                      />
                    </span>
                  </div>
                  <div className="  addTocartButtonDiv   d-flex justify-content-center align-items-center ">
                    {/* <div className=" h-100 w-50 d-flex justify-content-center align-items-center">
                <span onClick={productQuantityDecreased}>
                  <i
                    className="fa fa-minus minusIcon"
                    style={{ cursor: "pointer" }}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span className="productQuantity">{productQuantity}</span>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span onClick={productQuantityIncreased}>
                  <i
                    className="fa fa-plus plusIcon"
                    style={{ cursor: "pointer" }}
                  />
                </span>
              </div> */}
                    <div className=" d-flex justify-content-center align-items-center p-2">
                      <Button
                        className="addToCartButton d-flex justify-content-center align-items-center border-0"
                        onClick={addToCartButton}
                        disabled={
                          submitting ||
                          productQuantity < 1 ||
                          product.outOfStock
                        }
                      >
                        <span>{trans.addToCart}</span>
                        <i className="fa fa-shopping-cart" />
                        {submitting ? <Spinner animation="grow" /> : null}
                      </Button>
                      &nbsp;
                      {removeData ? (
                        <Button
                          className="addToCartButton d-flex justify-content-center align-items-center border-0"
                          onClick={removeFromWishList}
                          // disabled={wishlistSubmiting || productQuantity < 1}
                        >
                          <span>Remove</span>
                          {/* <i className="fas fa-heart"></i> */}
                          {wishlistSubmiting ? (
                            <Spinner animation="grow" />
                          ) : null}
                        </Button>
                      ) : (
                        <Button
                          className="addToCartButton d-flex justify-content-center align-items-center border-0"
                          onClick={addToWishlist}
                          // disabled={wishlistSubmiting || productQuantity < 1}
                        >
                          <span>{trans.addToWishlist}</span>
                          <i className="fas fa-heart"></i>
                          {wishlistSubmiting ? (
                            <Spinner animation="grow" />
                          ) : null}
                        </Button>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div
              className="w-10 closeButtonProductModalDeskTop m-3"
              onClick={() => {
                setLgShow(false);
                setShowLoginMsg(false);
                setSubmitting(false);
                setProductQuantity(1);
              }}
            >
              {/* <Modal.Header closeButton></Modal.Header> */}
              <div
                style={{
                  color: "black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                X
              </div>
            </div>
          </div>
          {showLoginMsg ? (
            <Alert
              variant="danger"
              className="text-center mt-3 mb-0"
              onClose={() => setShowLoginMsg(false)}
              dismissible
            >
              You must be logged in. Please{" "}
              <Button variant="success" onClick={headToLogin}>
                Login
              </Button>
            </Alert>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>

      {/* MODAL ENDS */}

      {/* CONTINUE SHOPPING MODAL */}
      <Modal
        size="lg"
        show={showSuccessModal}
        onHide={closeProductSuccessModal}
        aria-labelledby="example-modal-sizes-title-lg"
        className="modelMainDiv"
        centered
      >
        <Modal.Header>
          <h5>This item has been added to the cart.</h5>
          <button
            className="btn btn-outline-secondary btn-circle"
            onClick={closeProductSuccessModal}
          >
            <i className="fa fa-times"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <img
                  src={productUrl(product.banner)}
                  className="img-fluid imgSize"
                  alt="cat-product"
                />
              </div>
              <div className="col-lg-8 col-md-6 col-sm-12">
                <div>
                  <span className="font-weight-bold">Product: </span>
                  {transName(product)}
                </div>
                <div>
                  <span className="font-weight-bold">Quantity: </span>
                  {addedQuantity}
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-4 mb-3 d-flex justify-content-between flex-wrap">
            <Button
              variant="info"
              className="rounded-pill"
              onClick={closeProductSuccessModal}
            >
              {trans.continueShopping}
            </Button>
            <Button
              variant="success"
              className="rounded-pill"
              onClick={() => {
                history.push("/cart");
              }}
            >
              {trans.proceedToCart}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ProductSection;
