import React, { createContext, useState } from "react";

export const FeaturedContext = createContext();

const FeaturedContextProvider = (props) => {
  const [featured, setFeatured] = useState([]);

  return (
    <FeaturedContext.Provider value={{ featured, setFeatured }}>
      {props.children}
    </FeaturedContext.Provider>
  );
};

export default FeaturedContextProvider;
