import React, { useContext, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "../../scss/include/carousel.scss";
import "owl.carousel/dist/assets/owl.carousel.css";
import "../../css/owl.theme.default.css";
import { PromotionContext } from "../Context/Promotion";
import { imageUrl } from "../../utils/helper";
import api from "../../services/api";
import { consoleError } from "../../utils/helper";
import { BhavikaContext } from "../Context/Bhavika";
import { Link } from "react-router-dom";

const CarouselComponent = () => {
  const { promotion, setPromotion } = useContext(PromotionContext);
  const { sliders, setSliders } = useContext(BhavikaContext);
  useEffect(() => {
    async function getPromotion() {
      try {
        const res = await api.get("/api/v1/promotion");
        if (res && res.data) {
          setPromotion(res.data);
        }
      } catch (error) {
        consoleError(error);
      }
    }
    async function getSliders() {
      try {
        const res = await api.get("/api/v1/slider");
        if (res && res.data) {
          resetSlider(res.data);
        }
      } catch (error) {
        consoleError(error);
      }
    }
    getPromotion();
    getSliders();
  }, [setPromotion, setSliders]);

  function resetSlider(data) {
    var secondSlide = data[1];
    data[1] = data[data.length - 1];
    data[data.length - 1] = secondSlide;
    setSliders(data);
  }

  const [state] = useState({
    option: {
      items: 1,
      loop: true,
      dots: false,
      nav: true,
      margin: 20,
      autoplay: true,
      autoplayTimeout: 8000,
      autoplaySpeed: 500,
      // autoplayHoverPause: true,
      animateOut: "slideOutDown",
      animateIn: "flipInX",
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 1,
          margin: 0,
        },
        992: {
          items: 1,
        },
        1200: {
          items: 1,
        },
      },
    },
  });
  return (
    <>
      {(promotion && Array.isArray(promotion) && promotion.length > 0) ||
      (sliders && Array.isArray(sliders) && sliders.length > 0) ? (
        <OwlCarousel className="owl-theme" id="slider1" {...state.option}>
          {promotion
            .filter((item) => item.carousel)
            .map((item) => (
              <div key={item._id}>
                <Link to={`/promotion/${item.slug}`}>
                  <img
                    className="mx-auto d-block"
                    src={imageUrl(item.banner)}
                    alt={"carousel"}
                  />
                </Link>
              </div>
            ))}
          {sliders.slice(0, 15).map((item) => (
            <div key={item?._id}>
              {item?.link ? (
                String(item?.link).includes("bhavika.online") ? (
                  <a href={item?.link}>
                    <img
                      className="mx-auto d-block"
                      src={imageUrl(item?.banner)}
                      alt={"carousel"}
                    />
                  </a>
                ) : (
                  <a
                    href={item?.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="mx-auto d-block"
                      src={imageUrl(item?.banner)}
                      alt={"carousel"}
                    />
                  </a>
                )
              ) : (
                <img
                  className="mx-auto d-block"
                  src={imageUrl(item?.banner)}
                  alt={"carousel"}
                />
              )}
            </div>
          ))}
        </OwlCarousel>
      ) : null}
    </>
  );
};
export default CarouselComponent;
