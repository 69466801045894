import React from 'react';
import '../../scss/include/advertismentTypeCard.scss';
import LazyLoad from 'react-lazyload';


const AdvertismentTypeCard = ({contain,imgName}) =>{
    return(
        <>
        <div className="col-lg-2 col-md-2 col-12 advTypeMainCard p-0 ">
            <LazyLoad>
            <div className="advTypeImgDiv d-flex justify-content-center align-items-center">
                <img src={imgName} className="img-fluid h-100 p-2" loading="lazy" alt={imgName}/>
            </div>
            </LazyLoad>
            <div className="advTypeConetentDiv d-flex justify-content-center align-items-center">
                {contain}
            </div>
        </div>
        </>
    )
}


export default AdvertismentTypeCard;