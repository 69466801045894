import React, { createContext, useState } from "react";

export const BestBuyContext = createContext();

const BestBuyContextProvider = (props) => {
  const [bestBuy, setBestBuy] = useState([]);

  return (
    <BestBuyContext.Provider value={{ bestBuy, setBestBuy }}>
      {props.children}
    </BestBuyContext.Provider>
  );
};

export default BestBuyContextProvider;
