import React, { useContext, useEffect, useState } from "react";
import BreadcrumbComponent from "../CustomComponents/BreadcrumbComponent";

import "../../scss/include/_checkout.scss";
import "../../css/bootstrap.css";
// import CreditCardPayment from "../CustomComponents/CreditCardPayment";
import OurService from "../CustomComponents/OurService";

// import DeliveryAddress from "../CustomComponents/DeliveryAddress";
import { CartContext } from "../Context/Cart";
import { ServiceTax, VAT } from "../../utils/constants";
import api from "./../../services/api";
import { Alert, Button, Spinner, Modal } from "react-bootstrap";
import payMeImg from "../../assets/icons/payme.png";
// import pavImg from "../../assets/icons/pay.svg";
// import aliPayImg from "../../assets/icons/alipay.png";
// import payPalImg from "../../assets/icons/paypal.svg";
// import cashOnDelivery from "../../assets/icons/cashOnDelivery.png";
import { consoleError } from "../../utils/helper";
import { useHistory } from "react-router-dom";
import useTrans from "../hooks/useTrans";
import RealDeliveryAddress from "../CustomComponents/RealDeliveryAddress";
import {
  faPhoneAlt,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const RealCheckout = () => {
  const history = useHistory();
  const trans = useTrans();
  // const [accessToken, setAccessToken] = useState("");
  // const [cardNumber, setCardNumber] = useState("");
  // const [expiry, setExpiry] = useState("");
  // const [cvc, setCvc] = useState("");
  const [shippingPrice] = useState(0.0);
  const [selectedAddress, setSelectedAddress] = useState("");
  // const [displayQr, setQr] = useState(false);
  // const [message, setMessage] = useState("");

  const { realcart, setRealCart } = useContext(CartContext);

  const [promotion, setPromotion] = useState(null);
  const [discount, setDiscount] = useState(0.0);
  const [rewardPrice, setRewardPrice] = useState(0.0);
  const [useReward, setUseReward] = useState(false);
  const [rewardError, setRewardError] = useState("");
  const [coupon, setCoupon] = useState("");
  const [showCoupon, setShowCoupon] = useState(false);
  const [couponErrMsg, setCouponErrMsg] = useState("");
  const [submitting, setSubmitting] = useState(false);

  // const [paymentMethod, setPaymentMethod] = useState("payme");

  const [errorMessage, setErrorMessage] = useState("");

  const [placingOrder, setPlacingOrder] = useState(false);

  const [couponValid, setCouponValid] = useState(false);
  const [showRewardModal, setShowRewardModal] = useState(false);

  const closeRewardModal = () => {
    setShowRewardModal(false);
    setRewardError("");
  };
  const [rewardData, setRewardData] = useState({
    reward_balance: 0,
    used: 0,
    remaining: 0,
    dollar: 0,
  });

  const getRewardStatus = async () => {
    try {
      const res = await api.get("/api/v1/user/reward/status");
      if (res && res.ok) {
        setRewardData(res.data);
        if (res.data && res.data.dollar > 0) {
          setShowRewardModal(true);
        }
      }
    } catch (error) {
      consoleError(error);
    }
  };
  useEffect(() => {
    getRewardStatus();
  }, []);

  const toggleCoupon = () => {
    setShowCoupon(!showCoupon);
  };

  const cancelReward = () => {
    setRewardPrice(0.0);
  };

  const resetCoupon = () => {
    setCoupon("");
    setPromotion("");
    setDiscount(0.0);
    setCouponValid(false);
  };

  const applyCoupon = async (e) => {
    e.preventDefault();
    setCouponErrMsg("");
    if (coupon) {
      setSubmitting(true);
      try {
        const res = await api.get(`/api/v1/user/coupon/apply/${coupon}`);
        if (res.ok) {
          setCouponValid(true);
          setDiscount(res.data.discount);
          setPromotion(res.data.promotion);
        }
        setSubmitting(false);
      } catch (error) {
        consoleError(error);
        const message = error.message ? error.message : "error applying coupon";
        setCouponErrMsg(message);
        setSubmitting(false);
      }
    } else {
      setCouponErrMsg("Please enter coupon first");
    }
  };

  // useEffect(() => {
  //   if (localStorage.getItem('paid') === 'yes') {
  //     setMessage('Something went wrong. Please try again')
  //   }
  // })
  const SubTotal = realcart
    .reduce((acc, item) => (acc += item.quantity * item.product.price), 0)
    .toFixed(2);

  const Total = (
    ((100 + VAT) / parseFloat(100)) *
      (((100 + ServiceTax) / parseFloat(100)) * SubTotal) +
    +shippingPrice -
    +discount -
    +rewardPrice
  ).toFixed(2);

  const placeOrder = async (paymentMethod) => {
    try {
      if (!selectedAddress) {
        alert("Please add shipping address first.");
        return;
      }
      if (!paymentMethod) {
        alert("Please select a payment method first.");
        return;
      }
      setPlacingOrder(true);
      const res = await api.post("/api/v1/user/realorder/place", {
        promotion,
        paymentMethod: paymentMethod,
        useReward,
      });
      if (res && res.ok) {
        const order = res.data;
        setPlacingOrder(false);
        setRealCart([]);
        history.push(`/myorders/${order._id}`);
      }
    } catch (error) {
      consoleError(error);
      setErrorMessage(
        error.message ? error.message : "Error placing order. Please try again."
      );
      setPlacingOrder(false);
    }
  };

  return (
    <>
      <BreadcrumbComponent
        from="Real Tast Of India"
        fromHref="/real-taste-of-india"
        toHref="/checkouts"
        to={trans.checkout}
      />
      {/* <pre>{JSON.stringify({ selectedAddress }, null, 2)}</pre> */}
      {/* <DeliveryAddress
        setShippingPrice={setShippingPrice}
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
      /> */}

                <div className="container col-12 col-lg-8 my-lg-5">
            <h4>
              {trans.orderSummary} <div className="borderBottom"></div>
            </h4>
            <div className="table-responsive">
              <table className="table table-striped table-sm">
                <thead>
                  <tr>
                    <th className="text-left">Product</th>
                    <th>Quantity</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {realcart.map((item, index) => (
                    <tr key={index}>
                      <td className="text-left">{item.product.name}</td>
                      <td>{item.quantity}</td>
                      <td>${Number(item.product.price).toFixed(2)}</td>
                    </tr>
                  ))}
                  {/* <tr>
                  <td>Service Tax</td>
                  <td></td>
                  <td colSpan={2}>{ServiceTax}%</td>
                </tr>
                <tr>
                  <td>VAT</td>
                  <td></td>
                  <td colSpan={2}>{VAT}%</td>
                </tr> */}
                  <tr>
                    <td className="d-flex justify-content-start align-items-start font-weight-bold">Shipping Price</td>
                    <td></td>
                    <td colSpan={2} className="font-weight-bold">${shippingPrice.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td className="d-flex justify-content-start align-items-start font-weight-bold">Discount</td>
                    <td></td>
                    <td colSpan={2} className="font-weight-bold">${discount.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td className="d-flex justify-content-start align-items-start font-weight-bold">Reward</td>
                    <td></td>
                    <td colSpan={2} className="font-weight-bold">${rewardPrice.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td className="d-flex justify-content-start align-items-start font-weight-bold">Total</td>
                    <td></td>
                    <td colSpan={2} className="font-weight-bold">${Total}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div>
              {Number(rewardPrice) > 0 ? (
                <Button
                  size="sm"
                  variant="danger"
                  className="rounded-pill"
                  onClick={cancelReward}
                >
                  Cancel Reward
                </Button>
              ) : rewardData.dollar > 0 ? (
                <Button
                  size="sm"
                  variant="info"
                  className="rounded-pill"
                  onClick={getRewardStatus}
                >
                  Use Reward
                </Button>
              ) : null}
            </div>

            <span
              onClick={toggleCoupon}
              style={{
                color: "blue",
                textDecorationLine: "underline",
                cursor: "pointer",
              }}
            >
              {trans.haveCoupon}
            </span>
            {showCoupon ? (
              <>
                <form className="form-inline" onSubmit={applyCoupon}>
                  <input
                    type="text"
                    value={coupon}
                    className={"form-control input-box"}
                    placeholder={trans.discountCode}
                    onChange={(e) => setCoupon(e.target.value.toUpperCase())}
                    disabled={couponValid}
                  />
                  {submitting ? (
                    <Spinner animation="border" variant="primary" size="md" />
                  ) : null}

                  {couponValid ? (
                    <>
                      <div
                        style={{
                          background: "#21920f",
                          borderRadius: "50%",
                          height: "30px",
                          width: "30px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <i
                          className="fa fa-check"
                          style={{ color: "white" }}
                        ></i>
                      </div>
                      <span
                        style={{
                          color: "red",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={resetCoupon}
                      >
                        cancel
                      </span>
                    </>
                  ) : (
                    <Button
                      // size="sm"
                      className={"w-25 discount-submit rounded-pill border-0"}
                      disabled={submitting || couponValid}
                      type={"submit"}
                      style={{
                        background: "#21920f",
                      }}
                    >
                      {trans.apply}
                    </Button>
                  )}
                </form>
                {couponErrMsg ? (
                  <div style={{ color: "red" }}>{couponErrMsg}</div>
                ) : null}
              </>
            ) : null}
          </div>
          <div className="container col-12 col-lg-8">
      <RealDeliveryAddress setSelectedAddress={setSelectedAddress} />
      </div>
      <div className="container col-12 col-lg-8 checkout-details mt-5">
        <div className="row checkout-content d-flex justify-content-between align-items-center">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <h4>
              {trans.selectPaymentMethod}
              <div className="borderBottom"></div>
            </h4>
            <ul
              className="nav nav-pills nav-justified mb-3 d-flex justify-content-center align-items-center"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item col-6">
                <a
                  className="nav-link show active"
                  id="pills-profile-tab"
                  data-toggle="pill"
                  href="#pills-profile"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  <img src={payMeImg} alt="pay me" className="paymeLogo" />
                </a>
              </li>
            </ul>
            <div
              className="tab-content"
              id="pills-tabContent"
              style={{ height: "100%" }}
            >
              <div
                className="tab-pane fade show active text-center payme-section"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                {errorMessage ? (
                  <Alert
                    variant="danger"
                    className="text-center mt-3 mb-0"
                    onClose={() => setErrorMessage("")}
                    dismissible
                  >
                    {errorMessage}
                  </Alert>
                ) : null}
                <Button
                  disabled={placingOrder}
                  className="paymeProceedButton rounded-pill col-9"
                  onClick={(e) => {
                    e.preventDefault();
                    placeOrder("payme");
                  }}
                  variant="success"
                >
                  {trans.confirmOrderWithPayme}
                </Button>
              </div>

              <div
                className="tab-pane fade text-center"
                id="pills-paypal"
                role="tabpanel"
                aria-labelledby="pills-paypal-tab"
              >
                <div className="form-submit text-center">
                  {/*<ReactPayPal totalPrice={total}/>*/}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12 h-100 p-0">
          <div className="order-info py-4 px-3"
          style={{
            backgroundColor:'#F3F3F3',
            borderRadius:'5px',
          }}
          >
        <p className="col-12 d-flex justify-content-center align-items-center">
          <FontAwesomeIcon icon={faPhoneAlt} style={{color:'#21920f'}}  className="mr-3"/>
          <a href="tel:24154777" style={{textDecoration:'none', fontSize:"17px"}}>2415 4777</a>
          <span style={{color:'black'}} className="m-2" >|</span>
          <a href="tel:91831538" style={{textDecoration:'none', fontSize:"17px"}}>9183 1538</a>
        </p>
        <p className="col-12  d-flex justify-content-center align-items-center">
          <FontAwesomeIcon icon={faClock} style={{color:'#21920f'}} className="mr-3"/>
          <span style={{textDecoration:'none', fontSize:"17px"}}>Last order before 9:30 pm</span>
        </p>
      </div>
          </div>
        </div>
        <OurService />
      </div>

      <Modal
        size="md"
        show={showRewardModal}
        onHide={closeRewardModal}
        aria-labelledby="example-modal-sizes-title-lg"
        className="modelMainDiv"
        centered
      >
        <Modal.Header>
          <h5>Apply Reward Dollar?</h5>
          <button
            className="btn btn-outline-secondary btn-circle"
            onClick={closeRewardModal}
          >
            <i className="fa fa-times"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12 col-sm-12">
              You can spend $
              <span className="font-weight-bold">{rewardData.dollar}</span>{" "}
              reward.
            </div>
            {rewardError ? (
              <Alert
                variant="danger"
                className="text-center mt-3 mb-0"
                onClose={() => setRewardError("")}
                dismissible
              >
                {rewardError}
              </Alert>
            ) : null}
          </div>
          <div className="container mt-4 mb-3 d-flex justify-content-between flex-wrap">
            <Button
              variant="info"
              className="rounded-pill"
              onClick={closeRewardModal}
            >
              Earn More
            </Button>
            <Button
              variant="success"
              className="rounded-pill"
              onClick={() => {
                if (Total < rewardData.dollar) {
                  setRewardError(
                    `Cart should have at least $${rewardData.dollar} to use reward.`
                  );
                  return;
                }
                setRewardPrice(rewardData.dollar);
                setUseReward(true);
                closeRewardModal();
              }}
            >
              {"Spend"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default RealCheckout;
