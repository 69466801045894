import React, { createContext, useState } from "react";

export const SubCategoryContext = createContext();

const SubCategoryContextProvider = (props) => {
  const [subCategory, setSubCategory] = useState([]);

  return (
    <SubCategoryContext.Provider value={{ subCategory, setSubCategory }}>
      {props.children}
    </SubCategoryContext.Provider>
  );
};

export default SubCategoryContextProvider;
