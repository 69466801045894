import React, { useContext, useEffect } from "react";
import "../../scss/include/_bestBuyCard.scss";
import { BhavikaContext } from "../Context/Bhavika";
import api from "../../services/api";

import ProductSection from "./ProductSection";
import { consoleLog } from "../../utils/helper";

const RandomProductCard = () => {
  const { mangoes, setMangoes } = useContext(BhavikaContext);
  const { topPicks, setTopPicks } = useContext(BhavikaContext);
  useEffect(() => {
    async function getAlphonso() {
      const mangoesRes = await api.get("/api/v1/brand/slug/alphonso-mangoes");
      if (mangoesRes && mangoesRes.data) {
        console.log(mangoesRes.data.products);
        setMangoes(mangoesRes.data.products[1]);
      }
    }
    async function getBestBuy() {
      // TODO: change url later
      const res = await api.get("/api/v1/toppicks/random");
      if (res && res.data) {
        console.log(res.data);
        setTopPicks(res.data);
      }
    }
    getAlphonso();
    getBestBuy();
  }, [setTopPicks]);

  return (
    <>
      <div className="container-fluid best-buy-random ">
        {/* col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2 */}
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
            <React.Fragment>
              <ProductSection product={mangoes} type={"topPicksProduct"} />
            </React.Fragment>
          {topPicks.map((product, index) => (
            <React.Fragment key={index}>
              <ProductSection product={product} type={"topPicksProduct"} />
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};
export default RandomProductCard;
