import React from "react";
import "../../scss/include/error404.scss";
import vectorImage from "../../assets/Others/vector right.svg";

const ErrorPage404 = () => {
  return (
    <>
      <div className="container  p-0">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-12 d-flex  justify-content-center align-items-center  p-0">
            <div className="col-lg-12 col-md-12 col-12  h-100 d-flex flex-column justify-content-center align-items-center text-center">
              <span className="Text404heading">404</span>
              <span className="text404 font-weight-bold">
                OOPS! PAGE NOT FOUND
              </span>
              <span className="text404 mb-3">
                Sorry but the page you are looking for does not exist, or have
                been removed, or name changed, or temporary unavailable
              </span>
              <a href="/"><button type="btn" className="btn" style={{backgroundColor:'yellowgreen',color:'white'}}>Back To HomePage</button></a>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-12 d-flex justify-content-center align-items-center error404imageDiv">
            <img
              style={{ height: "70vh" }}
              src={vectorImage}
              className="img-fluid"
              alt="builder_vector_image"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default ErrorPage404;
