import React from "react";
import "../../scss/include/inStoreAdvertisment.scss";
import AdvertismentTypeHeaderSection from "../CustomComponents/AdvertismentTypeHeaderSection";
import BreadcrumbComponent from "../CustomComponents/BreadcrumbComponent";

const RadioAdvertisment = () => {
  return (
    <>
      <BreadcrumbComponent
        from="Home"
        fromHref="/"
        to="Advertise with us"
        toHref="/advertise-with-us"
        thirdPage="Radio Advertisment"
        thirdPageHref="/advertise-with-us/Storeradio"
      />

      <AdvertismentTypeHeaderSection />

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 d-flex flex-column justify-content-center">
            <span className="advDescInStore">
              Every Bhavika grocery store has a customized audio feed that plays
              day parted mood mapped music inside the stores creating an audio
              environment where shoppers feels comfortable, relaxed and happy to
              spend time. The Music is played in Hindi, English and Regional
              languages as per the city feed. Advertisers can run their jingles
              or ad spots in day parts and communicate their brand
              message/offers through in-store Bhavika grocery store Radio. An
              advertiser can avail 4 spots per hour to run his commercial.
            </span>
            <span className="advDescInStore mt-2">
              To know more about the radio advertising, please feel free to
              contact us via email:{" "}
              <a
                href="mailto:info@bhavika.online"
                style={{ textDecoration: "underline" }}
              >
                info@bhavika.online
              </a>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default RadioAdvertisment;
