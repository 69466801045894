import { UPLOAD_URL } from "../config";

export const toTitleCase = (str) => {
  if (str)
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  return str;
};

export const consoleLog = (data) => {
  if (process.env.NODE_ENV === "development") {
    console.log(data);
  }
};

export const consoleError = (data) => {
  if (process.env.NODE_ENV === "development") {
    console.error(data);
  }
};

export const emailIsValid = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const isJSON = (str) => {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
};

export const getInitials = (name = "") =>
  name
    .replace(/\s+/, " ")
    .split(" ")
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join("");
export const getFirstName = (name = "") => name.split(" ")[0];

export function imageUrl(url) {
  if (url) {
    return `${UPLOAD_URL}${url}`;
  }
  return `/image.png`;
}

export function avatarUrl(url) {
  if (url) {
    if (url.includes("https://") || url.includes("http://")) return url;
    return `${UPLOAD_URL}${url}`;
  }
  return `/avatar.png`;
}

export function productUrl(url) {
  if (url) {
    if (url.includes("https://") || url.includes("http://")) return url;
    return `${UPLOAD_URL}${url}`;
  } else return "/placeholder.jpg";
}

export function setDefaultImage(e) {
  e.target.src = "/image.png";
}
export function setDefaultAvatar(e) {
  e.target.src = "/avatar.png";
}

export const getRewardDollar = (balance) => {
  let reward_dollar = 0;
  let remaining_balance = Number(balance) || 0;
  if (remaining_balance < 300) {
    reward_dollar = 0;
  } else {
    remaining_balance = remaining_balance - 300;
    reward_dollar = 5;
    const remaining = Math.floor(remaining_balance / 100) * 2;
    reward_dollar += remaining;
    remaining_balance = remaining_balance % 100;
  }
  return reward_dollar;
};
