import React, { useEffect, useState } from "react";
import SuccessMessage from "../CustomComponents/SuccessMessage";
import FailedMessage from "../CustomComponents/FailedMessage";
import "../../scss/include/passwordReset.scss";
import Axios from "axios";
import { API_BASE_URL } from "../../config";
import { useHistory } from "react-router-dom";

const PasswordResetPage = () => {
  const history = useHistory();
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const [queryToken, setQueryToken] = useState("");
  const [expiredMessage, setExpiredMessage] = useState("");
  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let token = params.get("token");
    if (token) {
      setQueryToken(token);
      Axios.get(`${API_BASE_URL}/api/v1/auth/forgot-password/${token}`)
        .then((res) => {
          // consoleLog(res)
        })
        .catch((err) => {
          setExpiredMessage("Token Expired. Please try forgot password again.");
        });
    } else {
      window.location.href = "/";
    }
  }, []);

  const [passwordState, setPasswordState] = useState({
    newPassword: "",
  });
  const [confirmPasswordState, setConfirmPasswordState] = useState("");
  const [passnotmatch, setPassNotMatch] = useState("");
  const [showHideState, setShowHideState] = useState({
    type: "password",
  });
  const changeHandler = (event) => {
    const { name, value } = event.target;

    setPasswordState((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const confirmPasswordChangeHandler = (event) => {
    setConfirmPasswordState(event.target.value);
  };

  const submitHandler = () => {
    if (passwordState.newPassword !== confirmPasswordState) {
      setPassNotMatch(
        "Password didn't match.Please enter same password on both field"
      );
      return;
    }
    Axios({
      method: "post",
      url: `${API_BASE_URL}/api/v1/auth/forgot-password/${queryToken}`,
      data: passwordState,
      headers: {
        "content-type": "application/json;charset=utf-8",
      },
    })
      .then((res) => {
        setSuccessMessage(res.data.message);
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
      })
      .catch((err) => {
        setFailedMessage(
          "Token Expired. Please try forgot password again.Please Wait Redirercting to homepage..."
        );
        setTimeout(function () {
          window.location.href = "/";
        }, 3000);
      });
  };

  const successPasswordChanegHandler = () => {
    history.push("/");
  };

  const showHide = (event) => {
    setShowHideState({
      type: showHideState.type === "text" ? "password" : "text",
    });
  };

  return (
    <>
      {expiredMessage ? (
        <div>
          <FailedMessage msg={expiredMessage} />
          <div className="col-lg-12 col-md-12 col-12 d-flex justify-content-center align-items-center">
            {/* <button
              onClick={successPasswordChanegHandler}
              className="btn"
              style={{ backgroundColor: "yellowgreen", color: "white" }}
            >
              Ok
            </button> */}
          </div>
        </div>
      ) : successMessage ? (
        <>
          <SuccessMessage msg={successMessage} />
          <div className="col-lg-12 col-md-12 col-12 d-flex justify-content-center align-items-center">
            {/* <button
              onClick={successPasswordChanegHandler}
              className="btn"
              style={{ backgroundColor: "yellowgreen", color: "white" }}
            >
              Ok
            </button> */}
          </div>
        </>
      ) : failedMessage ? (
        <>
          <FailedMessage msg={failedMessage} />
          <div className="col-lg-12 col-md-12 col-12 d-flex justify-content-center align-items-center">
            <button
              onClick={successPasswordChanegHandler}
              className="btn"
              style={{ backgroundColor: "yellowgreen", color: "white" }}
            >
              Ok
            </button>
          </div>
        </>
      ) : (
        <div className="container-fluid d-flex justify-content-center align-items-center  mt-3">
          <div className="row col-lg-4 col-md-4 col-12 fpMaiDiv">
            <div className="col-lg-12 col-md-12 col-12 d-flex justify-content-center align-items-center font-weight-bold mt-2">
              <span style={{ fontSize: "20px" }}>Reset Your Password</span>
            </div>
            <div className="col-lg-12 col-md-12 col-12 text-center mt-2">
              <span style={{ fontSize: "17px" }}>
                Please enter your new password below to access your account
              </span>
            </div>

            {passnotmatch ? (
              <span
                style={{
                  color: "red",
                  fontSize: "15px",
                  textAlign: "center",
                }}
                className="mt-2"
              >
                {passnotmatch}
              </span>
            ) : (
              ""
            )}

            <div className="w-100 d-flex flex-column mt-2">
              <label htmlFor="password" className="npLabal">
                New Password
              </label>
              <div className="form-group position-relative">
                <input
                  className="form-control input-lg rounded-0"
                  type={showHideState.type}
                  name="newPassword"
                  placeholder="New Password"
                  value={passwordState.newPassword}
                  onChange={(e) => changeHandler(e)}
                  required=""
                />
                <i
                  className={`fa fa-lg position-absolute ${
                    showHideState.type === "password"
                      ? "fa-eye"
                      : "fa-eye-slash"
                  }`}
                  onClick={showHide}
                ></i>
              </div>
            </div>

            {/* Confirm Password */}
            {/* <div className="col-lg-12 col-md-12 col-12  d-flex flex-column  mt-3">
              <label htmlFor="password" className="npLabal">
                   Confirm Password
              </label>
              <input
                type={showHideState.type}
                name="confirmPasswordState"
                placeholder="Confirm Password"
                value={confirmPasswordState}
                onChange={(e) => confirmPasswordChangeHandler(e)}
                style={{ outline: "none" }}
                className="form-control"
              />
            </div> */}

            <div className="w-100 d-flex flex-column mt-2">
              <label htmlFor="password" className="npLabal">
                Confirm Password
              </label>
              <div className="form-group position-relative">
                <input
                  className="form-control input-lg rounded-0"
                  type={showHideState.type}
                  name="confirmPasswordState"
                  placeholder="Confirm Password"
                  value={confirmPasswordState}
                  onChange={(e) => confirmPasswordChangeHandler(e)}
                  required=""
                />
                <i
                  className={`fa fa-lg position-absolute ${
                    showHideState.type === "password"
                      ? "fa-eye"
                      : "fa-eye-slash"
                  }`}
                  onClick={showHide}
                ></i>
              </div>
            </div>
            {/* Confirm Passwords Ends */}

            <div className="col-lg-12 col-md-12 col-12 d-flex justify-content-center align-items-center mt-3 mb-3">
              <button
                type="submit"
                className="btn"
                style={{ backgroundColor: "yellowgreen", color: "white" }}
                onClick={submitHandler}
              >
                Change Password
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default PasswordResetPage;
