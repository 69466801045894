import React from "react";
import WishlistProductCard from "../CustomComponents/WishlistProductCard";

const Wishlist = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row  d-flex justify-content-center align-items-center">
          <div className="col-lg-3 col-md-3 col-12  d-flex justify-content-center align-items-center">
            <span className="font-weight-bold" style={{ fontSize: "20px" }}>
              Wish List
            </span>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-3">
        <div className="row  d-flex justify-content-center align-items-center">
          <WishlistProductCard />
        </div>
      </div>
    </>
  );
};
export default Wishlist;
