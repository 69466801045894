import React from "react";
import "../../scss/include/shipping.scss";
import BreadcrumbComponent from "../CustomComponents/BreadcrumbComponent";

const Shipping = () => {
  return (
    <>
      <BreadcrumbComponent
        from="Home"
        fromHref="/"
        to="Delivery Schedule"
        toHref="/shipping"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col=lg-12 col-md-12 col-12">
            <span className="shippingPageTopText">
              Please click on your region to find your city delivery schedule.
              If your city/area not listed here then WhatsApp us on{" "}
              <a href="https://wa.me/+85297777510">+852-9777-7510</a> or call us
              on <a href="tel:+85224154777">+852-2415-4777</a> or drop a mail to{" "}
              <a href="mailto:info@bhavika.online">info@bhavika.online</a>
            </span>
          </div>
        </div>
      </div>

      {/* Tab Pan Starts */}
      <div className="container-fluid mt-5">
        <div className="row d-flex flex-column justify-content-center align-items-center">
          {/* Nab Menu Starts */}
          <div className="col-lg-12 col-md-12 col-12   tabMenu">
            {/* <table border="1" style={{width:'100%'}} className="tabMenuTable">
                        <tr>
                        <a className="nav-tabs active" data-toggle="tab" href="#menu1" > <td className="d-flex justify-content-center align-items-center"><span className="shippingLocationTabMenu">Hong Kong</span></td></a>
                        </tr>
                        <tr>
                        <a data-toggle="tab" href="#menu2" className="nav-tabs"><td className="d-flex justify-content-center align-items-center"><span className="shippingLocationTabMenu">Kowloon</span></td></a>
                        </tr>
                        <tr>
                        <a data-toggle="tab" href="#menu3" className="nav-tabs"><td className="d-flex justify-content-center align-items-center"><span className="shippingLocationTabMenu">New Territories</span></td></a>
                        </tr>
                        <tr>
                        <a data-toggle="tab" href="#menu4" className="nav-tabs"><td className="d-flex justify-content-center align-items-center"><span className="shippingLocationTabMenu">Outlying Island</span></td></a>
                        </tr>
                    </table> */}
            <ul className="nav nav-tabs">
              <li className="active">
                <a
                  className="btn btn-light  dropdown-toggle w-100"
                  data-toggle="tab"
                  href="#menu1"
                >
                  <span>Hong Kong</span>
                </a>
              </li>
              <li>
                <a
                  className="btn btn-light  dropdown-toggle w-100"
                  data-toggle="tab"
                  href="#menu2"
                >
                  <span>Kowloon</span>
                </a>
              </li>
              <li>
                <a
                  className="btn btn-light  dropdown-toggle w-100"
                  data-toggle="tab"
                  href="#menu3"
                >
                  <span>New Territories</span>
                </a>
              </li>
              <li>
                <a
                  className="btn btn-light  dropdown-toggle w-100"
                  data-toggle="tab"
                  href="#menu4"
                >
                  <span>Outlying Island</span>
                </a>
              </li>
            </ul>
          </div>
          {/* Nab Menu Ends */}

          {/*Nav Contain Starts  */}
          <div className="col-lg-12  col-md-12 col-12 tab-content mt-2 p-0">
            {/* <span>Select The location</span> */}
            <div className="tab-content" style={{ width: "100%" }}>
              {/* Menu 1 */}
              <div
                id="menu1"
                // className="tab-pane fade"
                className="container-fluid tab-pane active"
              >
                <table border="1" style={{ width: "100%" }}>
                  <thead>
                    <tr style={{ backgroundColor: "#45464e", color: "white" }}>
                      <th>
                        <span>CITY</span>
                      </th>
                      <th>
                        <span>Order Delivery Time</span>
                      </th>
                      <th>
                        <span>Minimum Order Amount ($)</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span className="shippingAddress">Admiralty</span>
                      </td>
                      <td>
                        <span className="shippingAddress">
                          Wednesday OR Saturday (7-9PM)
                        </span>
                      </td>
                      <td>
                        <span className="shippingAddress">400</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="shippingAddress">Ap Lei Chau</span>
                      </td>
                      <td>
                        <span className="shippingAddress">
                          Wednesday OR Saturday (7-9PM)
                        </span>
                      </td>
                      <td>
                        <span className="shippingAddress">600</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="shippingAddress">Pok Fu Lam</span>
                      </td>
                      <td>
                        <span className="shippingAddress">
                          Wednesday OR Saturday (7-9PM)
                        </span>
                      </td>
                      <td>
                        <span className="shippingAddress">600</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="shippingAddress">Causeway Bay</span>
                      </td>
                      <td>
                        <span className="shippingAddress">
                          Wednesday OR Saturday (7-9PM)
                        </span>
                      </td>
                      <td>
                        <span className="shippingAddress">500</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="shippingAddress">Sai Kung</span>
                      </td>
                      <td>
                        <span className="shippingAddress">
                          Wednesday OR Saturday (7-9PM)
                        </span>
                      </td>
                      <td>
                        <span className="shippingAddress">400</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="shippingAddress">Sai Ying Pun</span>
                      </td>
                      <td>
                        <span className="shippingAddress">
                          Wednesday OR Saturday (7-9PM)
                        </span>
                      </td>
                      <td>
                        <span className="shippingAddress">400</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="shippingAddress">Central</span>
                      </td>
                      <td>
                        <span className="shippingAddress">
                          Wednesday OR Saturday (7-9PM)
                        </span>
                      </td>
                      <td>
                        <span className="shippingAddress">400</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="shippingAddress">Happy Valley</span>
                      </td>
                      <td>
                        <span className="shippingAddress">
                          Wednesday OR Saturday (7-9PM)
                        </span>
                      </td>
                      <td>
                        <span className="shippingAddress">500</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="shippingAddress">Kennedy Town</span>
                      </td>
                      <td>
                        <span className="shippingAddress">
                          Wednesday OR Saturday (7-9PM)
                        </span>
                      </td>
                      <td>
                        <span className="shippingAddress">400</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="shippingAddress">Mid-Levels</span>
                      </td>
                      <td>
                        <span className="shippingAddress">
                          Wednesday OR Saturday (7-9PM)
                        </span>
                      </td>
                      <td>
                        <span className="shippingAddress">350</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="shippingAddress">North Point</span>
                      </td>
                      <td>
                        <span className="shippingAddress">
                          Wednesday OR Saturday (7-9PM)
                        </span>
                      </td>
                      <td>
                        <span className="shippingAddress">350</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="shippingAddress">Quarry Bay</span>
                      </td>
                      <td>
                        <span className="shippingAddress">
                          Wednesday OR Saturday (7-9PM)
                        </span>
                      </td>
                      <td>
                        <span className="shippingAddress">350</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="shippingAddress">Sheung Wan</span>
                      </td>
                      <td>
                        <span className="shippingAddress">
                          Wednesday OR Saturday (7-9PM)
                        </span>
                      </td>
                      <td>
                        <span className="shippingAddress">400</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="shippingAddress">Tai Koo Shing</span>
                      </td>
                      <td>
                        <span className="shippingAddress">
                          Wednesday OR Saturday (7-9PM)
                        </span>
                      </td>
                      <td>
                        <span className="shippingAddress">400</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="shippingAddress">Wan Chai</span>
                      </td>
                      <td>
                        <span className="shippingAddress">
                          Wednesday OR Saturday (7-9PM)
                        </span>
                      </td>
                      <td>
                        <span className="shippingAddress">350</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Menu 1 Ends */}

              {/* Menu 2 */}
              <div id="menu2" className="container-fluid tab-pane fade">
                <table border="1" style={{ width: "100%" }}>
                  <thead>
                    <tr style={{ backgroundColor: "#45464e", color: "white" }}>
                      <th>
                        <span>CITY</span>
                      </th>
                      <th>
                        <span>Order Delivery Time</span>
                      </th>
                      <th>
                        <span>Minimum Order Amount ($)</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span>Ho Man Tin</span>
                      </td>
                      <td>
                        <span>Within 48 Hours (6-9PM)</span>
                      </td>
                      <td>
                        <span>400</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Laguna City</span>
                      </td>
                      <td>
                        <span>Thursday, Friday and Saturday (6-9PM)</span>
                      </td>
                      <td>
                        <span>350</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Cheung Sha Wan</span>
                      </td>
                      <td>
                        <span>Wednesday and Friday (6-9PM)</span>
                      </td>
                      <td>
                        <span>400</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Hung Hom</span>
                      </td>
                      <td>
                        <span>Within 48 Hours (6-9PM)</span>
                      </td>
                      <td>
                        <span>200</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Whampoa</span>
                      </td>
                      <td>
                        <span>Within 48 Hours (6-9PM)</span>
                      </td>
                      <td>
                        <span>250</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Jordan</span>
                      </td>
                      <td>
                        <span>Within 48 Hours (6-9PM)</span>
                      </td>
                      <td>
                        <span>400</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Kowloon City</span>
                      </td>
                      <td>
                        <span>Within 48 Hours (6-9PM)</span>
                      </td>
                      <td>
                        <span>500</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Kwun Tong</span>
                      </td>
                      <td>
                        <span>Within 72 Hours (6-9PM)</span>
                      </td>
                      <td>
                        <span>400</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Lai Chi Kok</span>
                      </td>
                      <td>
                        <span>Within 72 Hours (6-9PM)</span>
                      </td>
                      <td>
                        <span>500</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Lam Tin</span>
                      </td>
                      <td>
                        <span>Within 72 Hours (6-9PM)</span>
                      </td>
                      <td>
                        <span>500</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Yau Tong</span>
                      </td>
                      <td>
                        <span>Within 72 Hours (6-9PM)</span>
                      </td>
                      <td>
                        <span>500</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Ma Tau Wai</span>
                      </td>
                      <td>
                        <span>Within 72 Hours (6-9PM)</span>
                      </td>
                      <td>
                        <span>400</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Mei Foo Sun Chuen</span>
                      </td>
                      <td>
                        <span>Within 72 Hours (6-9PM)</span>
                      </td>
                      <td>
                        <span>500</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Mong Kok</span>
                      </td>
                      <td>
                        <span>Within 72 Hours (6-9PM)</span>
                      </td>
                      <td>
                        <span>400</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Sham Shui Po</span>
                      </td>
                      <td>
                        <span>Within 72 Hours (6-9PM)</span>
                      </td>
                      <td>
                        <span>500</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Tai Kok Tsui</span>
                      </td>
                      <td>
                        <span>Within 48 Hours (6-9PM)</span>
                      </td>
                      <td>
                        <span>400</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>To Kwa Wan</span>
                      </td>
                      <td>
                        <span>Within 48 Hours (6-9PM)</span>
                      </td>
                      <td>
                        <span>400</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Tsim Sha Tsui</span>
                      </td>
                      <td>
                        <span>Within 48 Hours (6-9PM)</span>
                      </td>
                      <td>
                        <span>200</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Tsim Sha Tsui East</span>
                      </td>
                      <td>
                        <span>Within 48 Hours (6-9PM)</span>
                      </td>
                      <td>
                        <span>200</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>West Kowloon</span>
                      </td>
                      <td>
                        <span>Within 72 Hours (6-9PM)</span>
                      </td>
                      <td>
                        <span>500</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Yau Ma Tei</span>
                      </td>
                      <td>
                        <span>Within 48 Hours (6-9PM)</span>
                      </td>
                      <td>
                        <span>200</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Menu 2 Ends*/}
              {/* Menu 3 Starts */}
              <div id="menu3" className="container-fluid tab-pane fade">
                <table border="1" style={{ width: "100%" }}>
                  <thead>
                    <tr style={{ backgroundColor: "#45464e", color: "white" }}>
                      <th>
                        <span>CITY</span>
                      </th>
                      <th>
                        <span>Order Delivery Time</span>
                      </th>
                      <th>
                        <span>Minimum Order Amount ($)</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span>Lohas Park</span>
                      </td>
                      <td>
                        <span>Tuesday, Thursday {"&"} Saturday (6-9PM)</span>
                      </td>
                      <td>
                        <span>300</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Tseung Kwan O</span>
                      </td>
                      <td>
                        <span>Tuesday, Thursday {"&"} Saturday (6-9PM)</span>
                      </td>
                      <td>
                        <span>150</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Ma On Shan</span>
                      </td>
                      <td>
                        <span>Friday or Saturday (6-9PM)</span>
                      </td>
                      <td>
                        <span>500</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Sha Tin</span>
                      </td>
                      <td>
                        <span>Friday or Saturday (6-9PM)</span>
                      </td>
                      <td>
                        <span>500</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Sheung Shui</span>
                      </td>
                      <td>
                        <span>Friday or Saturday (6-9PM)</span>
                      </td>
                      <td>
                        <span>800</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Tuen Mun</span>
                      </td>
                      <td>
                        <span>Friday or Saturday (6-9PM)</span>
                      </td>
                      <td>
                        <span>350</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>South Horizon</span>
                      </td>
                      <td>
                        <span>Wednesday & Sunday (6-9PM)</span>
                      </td>
                      <td>
                        <span>500</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Ma Wan - Park Island</span>
                      </td>
                      <td>
                        <span>Only on Sunday (4-7PM)</span>
                      </td>
                      <td>
                        <span>100</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Tin Shui Wai</span>
                      </td>
                      <td>
                        <span>Friday or Saturday (6-9PM)</span>
                      </td>
                      <td>
                        <span>500</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Tsing Yi</span>
                      </td>
                      <td>
                        <span>Within 48 Hours (6-9PM)</span>
                      </td>
                      <td>
                        <span>200</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Tsuen Wan</span>
                      </td>
                      <td>
                        <span>Within 72 Hours (6-9PM)</span>
                      </td>
                      <td>
                        <span>400</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Kwai Chung</span>
                      </td>
                      <td>
                        <span>Within 72 Hours (6-9PM)</span>
                      </td>
                      <td>
                        <span>400</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Yuen Long</span>
                      </td>
                      <td>
                        <span>Only on Friday (6-9PM)</span>
                      </td>
                      <td>
                        <span>550</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Menu 3 Ends */}
              {/* Menu 4 Starts */}
              <div id="menu4" className="container-fluid tab-pane fade">
                <table border="1" style={{ width: "100%" }}>
                  <thead>
                    <tr style={{ backgroundColor: "#45464e", color: "white" }}>
                      <th>
                        <span>CITY</span>
                      </th>
                      <th>
                        <span>Order Delivery Time</span>
                      </th>
                      <th>
                        <span>Minimum Order Amount ($)</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span>Chek Lap Kok</span>
                      </td>
                      <td>
                        <span>Within 24 Hours (6-9PM)</span>
                      </td>
                      <td>
                        <span>200</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          Lantau Island &nbsp;
                          <b>(Areas without entry restrictions)</b>
                        </span>
                      </td>
                      <td>
                        <span>Within 24 Hours (6-9PM)</span>
                      </td>
                      <td>
                        <span>200</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Tung Chung</span>
                      </td>
                      <td>
                        <span>Within 24 Hours (6-9PM)</span>
                      </td>
                      <td>
                        <span>100</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Discovery Bay</span>
                      </td>
                      <td>
                        <span>Wednesday, Friday {"&"} Sunday (4-7PM)</span>
                      </td>
                      <td>
                        <span>50</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Menu 4 Ends */}
            </div>
          </div>
          {/*Nav Contain Ends  */}
        </div>
      </div>
      {/* Tab Pan Ends */}

      {/* Privacy and security */}
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12  d-flex align-items-center">
            <i className="fas fa-lock mr-2"></i>
            <span className="privacySpan">Privacy and Security</span>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-lg-12 col-md-12 col-12   align-items-center">
            <i className="fas fa-check-circle mr-2"></i>
            <span className="shippingPrivacyPolicyDesc">
              Delivery will be delayed if a typhoon signal no. 8 is raised or
              black rainstorm warning is in effect.
            </span>
          </div>

          <div className="col-lg-12 col-md-12 col-12   align-items-center">
            <i className="fas fa-check-circle mr-2"></i>
            <span className="shippingPrivacyPolicyDesc">
              Delivery service is not available to areas that can not be reached
              directly by our vehicles or building without functioning lift to
              the 3rd Floor or above.
            </span>
          </div>

          <div className="col-lg-12 col-md-12 col-12   align-items-center">
            <i className="fas fa-check-circle mr-2"></i>
            <span className="shippingPrivacyPolicyDesc">
              As in some area, our Vehicles can not reach without permission
              letters, customers must collect the deliveries to these area in
              the car park.
            </span>
          </div>

          <div className="col-lg-12 col-md-12 col-12   align-items-center">
            <i className="fas fa-check-circle mr-2"></i>
            <span className="shippingPrivacyPolicyDesc">
              Please note that all goods will be delivered as per scheduled and
              to the best of our ability. We aim to deliver the goods when there
              is somebody in but we do not accept the responsibility for the
              goods left at the customer's request.
            </span>
          </div>
        </div>
      </div>
      {/* Privacy and security Ends */}
    </>
  );
};
export default Shipping;
