import React from "react";
import "../../scss/include/advertiseWithUs.scss";
import BreadcrumbComponent from "../CustomComponents/BreadcrumbComponent";
import ShopByHeading from "../CustomComponents/ShopByHeading";
// import Icon1 from '../../assets/icons/icon1.png';
// import AdvertiseWithUsCardCompnent from "../CustomComponents/AdvertiseWithUsCardCompnent";
// import Icon1 from "../../assets/icons/icon1.png";
// import Icon2 from "../../assets/icons/icon2.png";
// import Icon3 from "../../assets/icons/icon3.png";
// import Icon4 from "../../assets/icons/icon4.png";
// import Icon5 from "../../assets/icons/icon5.png";
// import AdvertismentPageImgCarousal from "../CustomComponents/AdvertsimentPageImgCarousal";
// import Carousel from "react-bootstrap/Carousel";
// import adv1 from "../../assets/Others/advImg1.png";

const AdvertiseWithUs = () => {
  return (
    <>
      <BreadcrumbComponent
        from="Home"
        fromHref="/"
        to="Advertise With Us"
        toHref="/advertise-with-us"
      />
      <ShopByHeading title="Advertise with Us" />

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12  d-flex flex-column">
            <span className="advWithUsHeadingSpan mb-2">
              Do you want to advertise your products and services on{" "}
              <a href="https://www.bhavika.online">www.bhavika.online</a>?
            </span>
            <span className="advWithUsDescSpan">
              We offer you the unique opportunity to connect directly with
              thousands of our customers.
            </span>
            <span className="advWithUsDescSpan mt-2">
              To find out about the advertising opportunities with us, please
              contact us via email at{" "}
              <a
                href="mailto:info@bhavika.online"
                style={{ textDecoration: "underline" }}
              >
                info@bhavika.online
              </a>
              .
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdvertiseWithUs;
