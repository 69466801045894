import React from "react";
import "../../scss/include/categoryPageProductHeading.scss";

const CategoryPageProductHeading = (props) => {
  return (
    <>
      {/* <div className="col-lg-2 col-md-2 col-5  p-1 d-flex justify-content-center align-items-center   m-1 categoryPageProductHeadingDiv"> */}
        <a
          href={props.redirectLink}
          style={{ textDecoration: "none", color: "black" }}
          type="button"
          className="btn col-lg-2 col-md-2 col-5  p-1 d-flex justify-content-center align-items-center   m-1 categoryPageProductHeadingDiv"
        >
          {props.name}
        </a>
      {/* </div> */}
    </>
  );
};
export default CategoryPageProductHeading;
