import React from "react";
import BreadcrumbComponent from "../CustomComponents/BreadcrumbComponent";
import hero_image from "../../assets/BhavikaRestaurant/hero_image.jpg";
import "../../scss/include/bhavikaRestaurant.scss";
import Menu_Veg_Main_Courses from "../../assets/BhavikaRestaurant/Menu_Veg Main Courses.jpg";
import Menu_Cocktails_Liquor from "../../assets/BhavikaRestaurant/Menu_Cocktails & Liquor.jpeg";
import Menu_Draft_Beer from "../../assets/BhavikaRestaurant/Menu_Draft Beer.jpeg";
import Menu_Drinks_Beverages from "../../assets/BhavikaRestaurant/Menu_Drinks & Beverages.jpg";
import Menu_Rice_Parathas from "../../assets/BhavikaRestaurant/Menu_Rice & Parathas.jpg";
import Menu_South_Indian from "../../assets/BhavikaRestaurant/Menu_South Indian.jpg";
import Menu_Starter_Chaat from "../../assets/BhavikaRestaurant/Menu_Starter & Chaat.jpg";
import Menu_Sweets_Dessert from "../../assets/BhavikaRestaurant/bhavikaMenuDesign.jpg";
import download_menu_button from "../../assets/BhavikaRestaurant/download menu button.png";
import menu_front_in_order from "../../assets/BhavikaRestaurant/menu front in order.jpg";
import menu_back from "../../assets/BhavikaRestaurant/menu back.jpg";
import south_indian from "../../assets/BhavikaRestaurant/south-indian.jpg";
import bh_menu_1 from "../../assets/BhavikaRestaurant/Page_14x-100.jpg";
import bh_menu_2 from "../../assets/BhavikaRestaurant/Page_24x-100.jpg";
import bh_menu_3 from "../../assets/BhavikaRestaurant/Page_34x-100.jpg";
import bh_menu_4 from "../../assets/BhavikaRestaurant/Page_44x-100.jpg";
import bh_menu_5 from "../../assets/BhavikaRestaurant/Page_54x-100.jpg";
import bh_menu_6 from "../../assets/BhavikaRestaurant/Page_64x-100.jpg";
import bh_menu_7 from "../../assets/BhavikaRestaurant/Page_74x-100.jpg";
import bh_menu_8 from "../../assets/BhavikaRestaurant/Page_84x-100.jpg";

import Bhavika_Menu from "../../assets/BhavikaRestaurant/menu.pdf";

import { Button } from "react-bootstrap";

const BhavikaRestaurant = () => {
  const imageNameList = [
    {
      name: `${bh_menu_1}`,
    },
    {
      name: `${bh_menu_2}`,
    },
    {
      name: `${bh_menu_3}`,
    },
    {
      name: `${bh_menu_4}`,
    },
    {
      name: `${bh_menu_5}`,
    },
    // {
    //   name: `${Menu_Cocktails_Liquor}`,
    // },
    {
      name: `${bh_menu_6}`,
    },
    {
      name: `${bh_menu_7}`,
    },
    {
      name: `${bh_menu_8}`,
    },
  ];

  const imageNameLiqureList = [
    {
      name: `${menu_front_in_order}`,
    },
    {
      name: `${menu_back}`,
    },
  ];
  return (
    <>
      <BreadcrumbComponent
        from="Home"
        fromHref="/home"
        to="Bhavika Restaurant"
      />
      <div className="container-fluid p-0">
        <div className="row p-0">
          <div className="col-12 p-0 hero_div">
            <img src={hero_image} className="img-fluid heroImage" />
          </div>
          <span className="pageTitle m-3">Our Menu</span>
          <div className="col-12 d-flex flex-wrap">
            {imageNameList.map((data, index) => (
              <div
                key={index}
                className="col-lg-3 col-md-3 col-12 p-1 menuImage"
              >
                <img src={data.name} className="img-fluid" alt={data.name} />
              </div>
            ))}
          </div>
          {/* <div className="col-12 d-flex flex-wrap">
            {imageNameLiqureList.map((data, index) => (
              <div key={index} className="col-lg-6 col-md-6 col-12 p-1">
                <img src={data.name} className="img-fluid" alt={data.name} />
              </div>
            ))}
          </div> */}
          <div className="col-12 d-flex justify-content-center align-items-center p-3">
            <a
              className="downloadBtn d-flex justify-content-center align-items-center col-lg-2 col-12 p-0"
              href={Bhavika_Menu}
              download={Bhavika_Menu}
              style={{ cursor: "pointer" }}
            >
              <img
                src={download_menu_button}
                alt="download_menu_pdf"
                className="img-fluid"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default BhavikaRestaurant;
