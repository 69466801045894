import React, { useState, useEffect } from "react";
import ProductSection from "./ProductSection";
import api from "../../services/api";
import { consoleError } from "../../utils/helper";
import PageSpinner from "./PageSpinner";
import { Alert } from "react-bootstrap";

const WishlistProductCard = () => {
  const [wishlistData, setWishlistData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    async function getWishList() {
      setErrorMessage("");
      try {
        const res = await api.get("/api/v1/user/wishlist");
        if (res && res.ok) setWishlistData(res.data[0].products);
      } catch (error) {
        consoleError(error);
        setErrorMessage("Error getting Wishlist.");
      } finally {
        setLoading(false);
      }
    }
    getWishList();
  }, []);

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <PageSpinner />
        </div>
      ) : wishlistData.length > 0 ? (
        wishlistData.map((item, index) => (
          <ProductSection key={index} product={item} removeData="Remove" />
        ))
      ) : (
        // <span style={{fontSize:'20px'}}>Your wishlist is empty</span>
        <div className="container-fluid d-flex justify-content-center align-items-center">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12 d-flex flex-column justify-content-center align-items-center">
              {errorMessage ? (
                <Alert
                  variant="danger"
                  className="text-center mt-3 mb-0"
                  onClose={() => setErrorMessage("")}
                  dismissible
                >
                  {errorMessage}
                </Alert>
              ) : (
                <>
                  <i className="fa fa-heart text-danger" />
                  <span style={{ fontSize: "20px" }}>
                    Your wishlist is empty
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default WishlistProductCard;
