import React from "react";
import { Link } from "react-router-dom";
import "../../scss/include/ReturnRefundPolicy.scss";
import BreadcrumbComponent from "../CustomComponents/BreadcrumbComponent";
import useTrans from "../hooks/useTrans";

const ReturnRefundPolicy = () => {
  const trans = useTrans();
  return (
    <>
      <BreadcrumbComponent
        from={trans.home}
        fromHref="/"
        to={trans.returnRefundPolicy}
        toHref="return-refund-policy"
      />
      <div className="container-fluid mb-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12  d-flex flex-column">
            <h2 className="topHeading mb-4">{trans.returnRefundPolicy}</h2>
            <div className="policyBox">
              {/* <h3 className="policyHeading">Return Policy</h3> */}
              <p className="policyDesc">
                If the items you receive have quality issues, please send email
                to{" "}
                <a href="mailto:sales@bhavika.online">sales@bhavika.online</a>{" "}
                with your order no. and pictures of your item within 7 days upon
                receipt. Items must be returned in the original box and must
                include all the original tag, label and packaging.
              </p>
              <p>
                Please note that only one refund request can be made per each
                order. Items cannot be returned/refunded for non-quality
                reasons.
              </p>
              <div>
                <p>Currently, we offer 2 refund methods including</p>
                <ul>
                  <li>
                    Refund to your dollar account, which can be used in your
                    next purchase.
                  </li>
                  <li>
                    Refund to your original account within 4-6 weeks, upon
                    confirmation.
                  </li>
                </ul>
              </div>
              <p>
                <strong>
                  If there is any dispute, <Link to="/">bhavika.online</Link>{" "}
                  reserves the right of final decision.
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ReturnRefundPolicy;
